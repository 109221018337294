import React, { useState, useRef } from "react"
import Script from "react-load-script"

export default function AddressTextField({
  addressType,
  onSelectResult,
  placeholder,
  value,
  name,
  label,
  registerFn,
  error,
}) {
  const [results, setResults] = useState({
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
  })

  var autocomplete = useRef()

  const handlePlaceSelect = () => {
    let addressObject = autocomplete.getPlace()

    var result = {}

    var componentForm = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name",
    }

    for (var i = 0; i < addressObject.address_components.length; i++) {
      var addressType_ = addressObject.address_components[i].types[0]
      if (componentForm[addressType_]) {
        var val =
          addressObject.address_components[i][componentForm[addressType_]]

        result[addressType_] = val
      }
    }

    
    

    setResults({
      full: document.getElementById("address"),
      street_address: result.street_number + " " + result.street_number,
      city: result.locality,
      state: result.administrative_area_level_1,
      zip_code: result.postal_code,
    })
    if(document.getElementById("address"))
    result.full=document.getElementById("address").value
   // console.log(results);
    if (addressType === "address") {
      

      onSelectResult(result,name)
      
    } else if (addressType === "cityState") {

      //console.log(name)
      onSelectResult(result,name)
      //console.log("state: ", addressObject)
      //Do nothing..for now
      //onSelectResult(results)
    }
  }

  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    var options = {}
    if (addressType === "address") {
      options = {
        types: ["geocode"],
        componentRestrictions: { country: "us" },
      } // To disable any eslint 'google not defined' errors
    } else if (addressType === "cityState") {
      options = {
        types: ["(cities)"],
        componentRestrictions: { country: "us" },
      }
    }

    // Initialize Google Autocomplete

    autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById(name),
      options
    )

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    autocomplete.setFields(["address_components"])

    // Fire Event when a suggested name is selected
    autocomplete.addListener("place_changed", handlePlaceSelect)
  }
  /*
  useEffect(() => {
    autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      {}
    )

    autocomplete.addListener("place_changed", handlePlaceSelect)

    return () => {
      autocomplete.removeEventListener("place_changed", handlePlaceSelect)
    }
  }, [])*/
  
return(
    <div>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.gapi_key}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <div className="relative mt-2 text-gray-600">
        <label
          className="block text-gray-900 text-sm font-bold mb-2"
          htmlFor={name}
        >
          {label}
        </label>
        <input
          placeholder={placeholder}
          className="h-12 border rounded w-full px-3 text-gray-700 focus:outline-none focus:shadow-outline text-2xl pr-10 bg-gray-200"
          id={name}
          autoComplete="off"
          type="text"
          name={name}
          defaultValue={value}
          //onChange={e => setSearchTerm(e.target.value)}
          ref={registerFn}
        />
        {error ? (
          <span className="text-red-500 text-xs italic">{error.message}</span>
        ) : (
          " "
        )}
      </div>
    </div>
  )
}
