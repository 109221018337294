import axios from "axios"
import ls from "local-storage"
import Base64 from "Base64"

class CarrierService {
  token = "";
  userid = "";

  constructor() {
    const usr = ls.get("wauid")
    try {
      this.token = JSON.parse(Base64.atob(usr)).Auth
      this.userid = JSON.parse(Base64.atob(usr)).Id
    } catch (err) {
      console.log(err);
    }
    //   console.log(this.token);
  }

  getToken() {
    return this.token;
  }

  getPlaces = async (search, type) => {
    const apiKey = process.env.gapi_key
    const url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${search}&types=${type}&language=en&key=${apiKey}
    &components=country:us`

    const res = await axios.get(url)
    return (res.data ? res.data : null)
  }

  getSaferCarrierByMC = async (mcnumber) => {
    const res = await axios.get(process.env.apiurl + "carriers/GetSaferCarrierByMCNum/" + mcnumber, {
      headers: { Authorization: "Basic " + this.token },
    })
    return (res.data ? res.data : null)
  }

  getSaferCarrier = async (number) => {
    const res = await axios.get(process.env.apiurl + "carriers/GetSaferCarrier/" + number, {
      headers: { Authorization: "Basic " + this.token },
    })
    return (res.data ? res.data : null)
  }

  GetCarrierById = async(id)=> {
    const res = await axios.get(process.env.apiurl + 'carriers/GetCarrier/' + id, {
      headers: { Authorization: "Basic " + this.token },
    })
    return (res.data ? res.data : null)
  }
  getCarriersByMC = async (mcnumber) => {
    const res = await axios.get(process.env.apiurl + "carriers/GetCarriersByMCNum/" + mcnumber, {
      headers: { Authorization: "Basic " + this.token },
    })
    return (res.data ? res.data : null)
  }
  GetCarriersByDOTNum = async(dotnum)=>{
    const res = await axios.get(process.env.apiurl + 'carriers/GetCarriersByDOTNum/' + dotnum, {
      headers: { Authorization: "Basic " + this.token },
    })
    return (res.data ? res.data : null)
  }
  Autocomplete = async (mcnumber) => {
    const search = { SuggestionText: mcnumber };
    const res = await axios.post(process.env.apiurl + "search/SuggestCarrier/" , search, {
      headers: { Authorization: "Basic " + this.token },
    })
    let results = [];
    if (res.data && res.data.Data) {
      results = res.data.Data.map((document) => document.Document);
    }
    return results;
   // return (res.data ? res.data : null)
  }
  carrierExist = async (mcnum) => {
    const res = await axios.get(process.env.apiurl + "carriers/CheckIfCarrierExist/" + mcnum, {
      headers: { Authorization: "Basic " + this.token },
    })
    return (res.data ? res.data : null)
  }

  create = async (carrierObj) => {
    const res = await axios.post(process.env.pdfurl + "carriers/CreateCarrierOnboarding", carrierObj, {
      headers: { Authorization: "Basic " + this.token },
    })
    return res.data
  }
    update = async (carrierObj) => {
    const res = await axios.put(process.env.pdfurl + "carriers/UpdateCarrierOnboarding", carrierObj, {
      headers: { Authorization: "Basic " + this.token },
    })
    return res.data
  }
  uploadDoc = async (formData, config) => {
    const res = await axios.post(process.env.apiurl + "blobs/CreateBlob", formData, config
    )
    return res.data
  }
  GetLogsByParentId(id){
    return this.http.get(`${process.env.apiurl}logs/GetLogsByParent/${id}`);
  }
}
export default CarrierService

