import React from "react"

export default function TextField({
  label, 
  type,
  placeholder,
  name,
  min,
  value,
  onChange,
  registerFn,
  error,
}) {
  return (
    <>    
      <div className="relative mb-2 mt-2 text-gray-600">
        <label
          className="block text-gray-900 text-sm font-bold mb-2"
          htmlFor={name}
        >
          {label}
        </label>
        <input
          className="h-12 border rounded w-full px-3 text-gray-700 focus:outline-none focus:shadow-outline text-2xl pr-10 bg-gray-200"
          id={name}
          type={type}
          name={name}   
          min={min}      
          defaultValue={value}
          onChange={onChange}
          placeholder={placeholder}
          ref={registerFn}
          autoComplete="off"

        />
        {error ? (
          <span className="text-red-500 text-xs italic">{error.message}</span>
        ) : (
          " "
        )}
      </div>
    </>
  )
}
