import React, { useState, useReducer } from "react"

import CarrierLookup from "./CarrierLookup"
import VerifyAddress from "./VerifyAddress"
import AdditionalContacts from "./AdditionalContacts"
import Capabilities from "./Capabilities"
import PreferredLanes from "./PreferredLanes"
import Payment from "./Payment"
import TextInfo from "./TaxInfo"
import InsuranceInfo from "./InsuranceInfo"
import CarrierAgreement from "./CarrierAgreement"
import SubmitContainer from "./SumbitPage"
import { Context, initialState, reducer } from "./store"

export default function FormContainer(props) {
  const [currentStep, setCurrentStep] = useState(1)

  function _next() {
    var a = currentStep <= 10 ? currentStep + 1 : null
    setCurrentStep(a)
  }

  function _prev() { 
    window.scrollTo({top: 0, behavior: 'instant'});
    var a = currentStep <= 1 ? 1 : currentStep - 1
    setCurrentStep(a)
     

  }


/*
  const PreviousButton = () => {
    if (currentStep !== 1) {
      return (
        <button
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          onClick={_prev}          
        >
          <span>Back</span>
        </button>
      )
    }
    return null
  }

  const NextButton = () => {
    if (currentStep < 10 && currentStep !== 1) {
      return <Next title="Next" action={_next} isEnabled={isFormValid}/>
    }
    return null
  }*/

  const [store, dispatch] = useReducer(reducer, initialState)

  return (
    <Context.Provider value={{ store, dispatch }}>
      <div className="container mx-auto p-6 md:my-0 sm:my-0 lg:my-10 rounded lg:shadow md:shadow-none  bg-white max-w-4xl">
        <h1 className="font-sans font-bold break-normal text-gray-700 text-xl mt-12 mb-2 lg:mt-0 md:text-3xl">
          Carrier Onboarding
        </h1>
        <CarrierLookup mcnum={props.mcnum} source={props.source} currentStep={currentStep} action={_next}/>
        <VerifyAddress currentStep={currentStep} back={_prev} next={_next}/>
        {currentStep === 3 ? <InsuranceInfo currentStep={currentStep} back={_prev} next={_next}/> : <></>}
        <AdditionalContacts currentStep={currentStep} back={_prev} next={_next} />
        <Capabilities currentStep={currentStep} back={_prev} next={_next} />
        <PreferredLanes currentStep={currentStep} back={_prev} next={_next}/>
        <Payment currentStep={currentStep} back={_prev} next={_next}/>
        <TextInfo currentStep={currentStep} back={_prev} next={_next}/>
      
        <CarrierAgreement currentStep={currentStep} back={_prev} next={_next}/>
        <SubmitContainer currentStep={currentStep} back={_prev} next={_next}/>
      </div>
    </Context.Provider>
  )
}
