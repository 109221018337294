import Address from "./address"
class Agent {
    Company = '';
    Name = '';
    Address = new Address();

    constructor(agent) {
        if (agent) {
        Object.assign(this,agent);
        }
    }
}

export default Agent