import axios from "axios"
import ls from "local-storage"
import Base64 from "Base64"
class LogService {
 
  token = '';
  constructor(){
      const usr = ls.get('wauid');
      try{
          this.token = JSON.parse(Base64.atob(usr)).Auth;
      }catch(err){

      }
      
  }
  CreateLog(log) {
    return axios.post(process.env.apiurl  + 'logs/CreateLog', log,{headers:{Authorization:'Basic ' + this.token }});
  }

  GetLogById(id){
    return axios.get(`${process.env.apiurl }logs/GetLog/${id}`);
  }

  GetLogsByParentId(id){
    return axios.get(`${process.env.apiurl }logs/GetLogsByParent/${id}`, {headers:{Authorization:'Basic ' + this.token }});
  }
}
export default LogService

