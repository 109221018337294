import React, { useContext, useState, Component } from "react"
import TextField from "./inputfields/TextField"
import Select from "./inputfields/Select"
import Next from "./buttons/Next"
import Back from "./buttons/Back"
import { Context } from "./store"
import { useForm } from "react-hook-form"
import moment from "moment"
import * as yup from "yup"
import FileUpload from "./upload/fileUpload"
import Insurance from "../../models/insurance"
import InputMask from "react-input-mask"
import Pencil from "../shared/pencil"

// const schema = yup.object().shape({
//   galpolicynum: yup.string().required("Liability Insurance Policy Number is required"),
//   galexpirationdate: yup.date().required("Liability Insurance Expiration date required.").test("Liability Insurance", "Expiration date has passed", function (
//     value
//   ) {
//     console.log(value, value.valueOf());
//     console.log(moment(new Date()).format("YYYY-MM-DD").valueOf(), moment(value).format("YYYY-MM-DD").valueOf());

//     if (moment(new Date()).format("YYYY-MM-DD").valueOf() > moment(value).format("YYYY-MM-DD").valueOf()) {
//       return false;
//     }
//     return true;

//   }).required("Expiration date is required"),
//   cargopolicynum: yup.string().required("Cargo Insurance Policy Number is required"),
//   cargoexpirationdate: yup.date().required("Cargo Insurance Expiration date required.").test("Liability Insurance", "Expiration date has passed", function (
//     value
//   ) {
//     console.log(value, value.valueOf());
//     console.log(moment(new Date()).format("YYYY-MM-DD").valueOf(), moment(value).format("YYYY-MM-DD").valueOf());
//     if (moment(new Date()).format("YYYY-MM-DD").valueOf() > moment(value).format("YYYY-MM-DD").valueOf()) {
//       return false;
//     }
//     return true;

//   }).required("Expiration date is required"),
// })

const insuranceType = ['Cargo', 'General'];
export default function InsuranceInfo({ currentStep, back, next }) {
  const { register, handleSubmit, errors, reset  } = useForm({
    //validationSchema: schema,
    mode: "onChange",
  })

  const d = new Date();
  const month = d.getMonth() > 10 ? (d.getMonth()+1) : '0'+ (d.getMonth()+1)
  const date = d.getDate() > 10 ? (d.getDate()+1) : '0'+ (d.getDate()+1)
  const mindate = d.getFullYear()+'-'+month+'-'+date;
  const [isAuthority, setIsAuthority] = useState(false)
  const [message, setMessage] = useState("")
  const [insuranceIndex, setInsuranceIndex] = useState(-1)
  const [isDeleted, setIsDeleted] = useState(false)
  let documentTypes = [
    "Carrier Authority"
  ]
  var extList = "";
  const { store, dispatch } = useContext(Context)
  if (currentStep !== 3) {
    return null
  }
  const handleSelect = e => {
    dispatch({ type: "setDocumentType", data: e.target.value })
  }

  const getExtension = (filename) => {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  const isImage = (file) => {

    for (var name in file) {
      var ext = getExtension(file[name].name);
      switch (ext.toLowerCase()) {
        case 'jpg':
        case 'png':
        case 'pdf':
        case 'jfif':
        case 'txt':
          return true;
      }

      extList += " " + ext.toLowerCase();

      return false;
    }
  }
  const handleFile = e => {
    extList = "";
    if (e.target.files.length === 0) {
      console.log(e.target.files);
      return;
    }
    var error = isImage(e.target.files);

    if (!error === true) {
      setIsAuthority(true);
      setMessage("An Invalid file type" + extList + " was found please try again with a (pdf , png or jpg) file");
      setTimeout(() => {
        setIsAuthority(false);
        setMessage("");
      }, 10000);
      return;
    }

    let docs = Array.from(e.target.files)
    e.target.value = ""
    docs.map(
      (file, index) => {
        var newName = file.name.split(".")[0] + "_" + new Date().getTime() + '.' + file.name.split(".")[1]
        var newFormData = new FormData()
        newFormData.append('file', file, newName)
        var newFile = newFormData.get('file')
        store.doc
          ? dispatch({
            type: "setFiles",
            data: {
              AttachmentPath: newFile.name,
              AttachmentType: store.doc,
              Date: new Date().toLocaleString(),
            },
          })
          : dispatch({
            type: "setFiles",
            data: {
              AttachmentPath: newFile.name,
              AttachmentType: store.DocumentType,
              Date: new Date().toLocaleString()
            }
          })

        dispatch({ type: "fileTemp", data: newFile })
      }
    )
  }
  const handleDocTable = async index => {

    dispatch({ type: "removeFileFromList", data: { data: index, type: "Authority" } })

  };


  const setData = data => {
    setIsAuthority(true);
    setMessage(data);
    setTimeout(() => {
      setIsAuthority(false);
      setMessage("");
    }, 5000);
  }

  const onSubmit = data => {
    
    console.log(data);
    var insurance = new Insurance({
      PolicyNumber:data.PolicyNumber,
      Type:data.InsuranceType,
      IssueDate:data.IssueDate,
      ExpirationDate:data.ExpirationDate,
      Agent:{
        Company:data.CompanyName,
        Name: data.AgentName,
        Address:{
          Phone: data.ContactNumber?[data.ContactNumber]:null,
          Email: data.EmailAddress?[data.EmailAddress]:null,
        }
      }
    })
    if(insuranceIndex > -1){
      store.carrier.InsuranceInfo[insuranceIndex] = Object.assign({}, insurance)
    }else{
      store.carrier.InsuranceInfo.push(insurance);
    }
    setInsuranceIndex(-1)
    
    reset();
  }
  

  function removeInsurance(index){
    if(index > -1){
      store.carrier.InsuranceInfo.splice(index,1)
      setIsDeleted(true);
    }
  }
  function editInsurance(index){
    if(index > -1){

      const ins = store.carrier.InsuranceInfo[index]
      setInsuranceIndex(index)
      reset({
        PolicyNumber:ins.PolicyNumber,
        Type:ins.Type,
        IssueDate:ins.IssueDate,
        ExpirationDate:ins.ExpirationDate,
        CompanyName:ins.Agent?ins.Agent.Company:'',
        AgentName:ins.Agent?ins.Agent.Name:'',
        ContactNumber: ins.Agent && ins.Agent.Address  && ins.Agent.Address.Phone && ins.Agent.Address.Phone[0] ? ins.Agent.Address.Phone[0] :'',
        EmailAddress: ins.Agent && ins.Agent.Address  && ins.Agent.Address.Ema && ins.Agent.Address.Phone[0] ? ins.Agent.Address.Phone[0] :'',
      });
    }
  }
  const nextClick = e =>{
    let check = store.carrier.Attachments ? store.carrier.Attachments.filter((item) => {
      return documentTypes.includes(item.AttachmentType) || item.IsDeleted === true }).map((e)=>{return e.AttachmentType }) : [];
    //let cargo = moment(data.cargoexpirationdate).format("YYYY-MM-DD");
    //let gal = moment(data.galexpirationdate).format("YYYY-MM-DD");

    if (check.length === 0) {
      setData("A carrier authority document is required");
      return;
    }

    //data.galexpirationdate = gal;
    //data.cargoexpirationdate = cargo;

    //dispatch({ type: "addInsuranceInfo", data })
    next()
  }
 
  
  return (
    <>
    {store.carrier.InsuranceInfo && store.carrier.InsuranceInfo.length > 0 ? 
          <div className="mb-3">
            {store.carrier.InsuranceInfo.map((obj, i)=>(
              <div key={i} className="mb-2">
                <strong>{obj.Type}:</strong>
                <span> {obj.PolicyNumber}</span>
                {obj.IssueDate ? (<span className="ml-2"><strong>Issued: </strong> {obj.IssueDate}</span>) : ''}
                <span className="ml-2"><strong>Exp: </strong> {obj.ExpirationDate}</span>
                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-0 px-2 rounded float-right ml-3" onClick={() => removeInsurance(i)}>
                  Delete
                </button>
                <button className="bg-yellow-400 hover:bg-yellow-600 text-black font-bold py-0 px-2 rounded float-right" onClick={() => editInsurance(i)}>
                  Edit
                </button>

                </div>
            ))}
            <hr></hr>
          </div>
          :null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-gray-700 text-xl mb-4">Insurance Information</h2>
        <div className="flex flex-wrap -mx-3">
          <div className="w-full md:w-1/2 px-3 md:mb-0">
            <TextField
              type="text"
              label="Policy Number*"
              name="PolicyNumber"
              registerFn={register({ required: true})}
            />
          </div>
          <div className="w-full md:w-1/2 px-3 md:mb-0">
            <Select
              type="date"
              label="Insurance Type*"
              name="InsuranceType"
              options={insuranceType}
              registerFn={register({ required: true})}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3">
          <div className="w-full md:w-1/2 px-3 md:mb-0">
            <TextField
              type="date"
              label="Date Issued*"
              name="IssueDate"
              registerFn={register}
            />
          </div>
          <div className="w-full md:w-1/2 px-3 md:mb-0">
            <TextField
              type="date"
              label="Expiration Date*"
              name="ExpirationDate"
              min={mindate}
              registerFn={register({ required: true})}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3">
          <div className="w-full md:w-1/2 px-3 md:mb-0">
            <TextField
              type="text"
              label="Company Name"
              name="CompanyName"
              registerFn={register}
            />
          </div>
          <div className="w-full md:w-1/2 px-3 md:mb-0">
            <TextField
              type="text"
              label="Agent Name"
              name="AgentName"
              registerFn={register}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3">
          <div className="w-full md:w-1/2 px-3 md:mb-0">
            <TextField
              type="text"
              label="Contact Number"
              name="ContactNumber"
              registerFn={register}
            />
          </div>
          <div className="w-full md:w-1/2 px-3 md:mb-0">
            <TextField
              type="email"
              label="Email Address"
              name="EmailAddress"
              registerFn={register}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 justify-end p-3">
        <button type="submit" className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
          Add Insurance
        </button>
        </div>
        
        <div className="mb-6" />
      </form>
      <h2 className="text-gray-700 text-xl mb-4">Upload Insurance and Carrier Authority Documents (PDF or JPG/PNG)</h2>
      <FileUpload
        state={store}
        handleTable={handleDocTable}
        handleSelect={handleSelect}
        handleFile={handleFile}
        type="Insurance"
      />
      {isAuthority ? <span className="text-red-500 text-xs italic">{message}</span> : null}
      <div className="mb-6" />
      <div className="flex items-center justify-between">
        <Back action={back} />
        <Next action={nextClick} />
      </div>


    </>
  )
}



// const insuranceType = ['Cargo', 'General'];
  

// class InsuranceInfo extends Component {
//   static store = Context;
//   static dispatch = Context;
  

//   //insuranceList = this.store.carrier.InsuranceInfo;

//   initialState = {
//     policyNumber: '',
//     policyType: '',
//     issueDate: '',
//     expirationDate: '',
//     company: '',
//     agentName: '',
//     phone: '',
//     email: '',
//     message: null,
//     currentStep:null
//   }
//   constructor(props) {
//     super(props)
//     this.state = this.initialState
//     // this.clear = this.clear.bind(this)
//     console.log(props.currentStep);
//     this.state.currentStep = props.currentStep
//     this.handleInputChange = this.handleInputChange.bind(this)
//     this.onFormSubmit = this.onFormSubmit.bind(this)
//     this.nextClick = this.nextClick.bind(this)
//     //this.getCarrierRate = this.getCarrierRate.bind(this)
    
//   }
  
//   onFormSubmit = e => {
//     console.log('submit')
//     e.preventDefault()
//     console.log(this.state)
//     //let val = this.input.value
//     //this.props.searchChange(val)
//   }
//   handleInputChange(event) {
//     const target = event.target
//     const value = target.value
//     const name = target.name
//     this.setState({
//       [name]: value,
//     })
//   }

//   nextClick = e =>{

//   }
//   insuranceInfo = new Insurance();

//   render(){
//     console.log(Context);

//     const {
//       policyNumber,
//     policyType,
//     issueDate,
//     expirationDate,
//     company,
//     agentName,
//     phone,
//     email,
//     message
//     } = this.state
//     if (this.state.currentStep !== 3) {
//       return <></>
//     }
//       return (
//           <>
//           {this.insuranceList && this.insuranceList.length > 0 ? 
//           <div>
//             {this.insuranceList.map((obj, i)=>(
//               <div>{obj.PolicyNumber}</div>
//             ))}
//           </div>
//           :null}

//       <form onSubmit={this.onFormSubmit}>
//         <h2 className="text-gray-700 text-xl mb-4">Insurance Information</h2>
//         <div className="flex flex-wrap -mx-3">
//           <div className="w-full md:w-1/2 px-3 md:mb-0">
//             <TextField
//               type="text"
//               label="Policy Number*"
//               name="policyNumber"
//               value={policyNumber}
//               onChange={this.handleInputChange}
//               required
//             />
//           </div>
//           <div className="w-full md:w-1/2 px-3 md:mb-0">
//             <Select
//               label="Insurance Type*"
//               name="policyType"
//               options={insuranceType}
//               value={policyType}
//               onChange={this.handleInputChange}
//               required
//             />
//           </div>
//         </div>
//         <div className="flex flex-wrap -mx-3">
//           <div className="w-full md:w-1/2 px-3 md:mb-0">
//             <TextField
//               type="date"
//               label="Date Issued*"
//               name="issueDate"
//               value={issueDate}
//               onChange={this.handleInputChange}
//             />
//           </div>
//           <div className="w-full md:w-1/2 px-3 md:mb-0">
//             <TextField
//               type="date"
//               label="Expiration Date*"
//               name="expirationDate"
//               value={expirationDate}
//               onChange={this.handleInputChange}
//               required
//             />
//           </div>
//         </div>
//         <div className="flex flex-wrap -mx-3">
//           <div className="w-full md:w-1/2 px-3 md:mb-0">
//             <TextField
//               type="text"
//               label="Company Name"
//               name="company"
//               value={company}
//               onChange={this.handleInputChange}
//             />
//           </div>
//           <div className="w-full md:w-1/2 px-3 md:mb-0">
//             <TextField
//               type="text"
//               label="Agent Name"
//               name="agentName"
//               value={agentName}
//               onChange={this.handleInputChange}
//             />
//           </div>
//         </div>
//         <div className="flex flex-wrap -mx-3">
//           <div className="w-full md:w-1/2 px-3 md:mb-0">
//           <InputMask
//             mask="(999) 999-9999"
//             type="tel"
//             id="driverPhone"
//             title="Contact Phone"
//             placeholder="(123) 456-7890"
//             className="appearance-none bg-gray-300 border-gray-300 w-full py-2 px-3 placeholder-gray-600 focus:outline-none rounded mt-2"
//             value={phone}
//             onChange={this.handleInputChange}
//             name="phone"
//           ></InputMask>
//           </div>
//           <div className="w-full md:w-1/2 px-3 md:mb-0">
//             <TextField
//               type="email"
//               label="Email Address"
//               name="email"
//               value={email}
//               onChange={this.handleInputChange}
//             />
//           </div>
//         </div>
//         <div className="flex flex-wrap -mx-3 justify-end p-3">
//         <button type="submit" className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
//           Add Insurance
//         </button>
//         </div>
//         <div className="mb-6" />
//       </form>
//       <h2 className="text-gray-700 text-xl mb-4">Upload Insurance and Carrier Authority Documents (PDF or JPG/PNG)</h2>
//       {/* <FileUpload
//         state={store}
//         handleTable={handleDocTable}
//         handleSelect={handleSelect}
//         handleFile={handleFile}
//         type="Insurance"
//       /> */}
//       {/* {isAuthority ? <span className="text-red-500 text-xs italic">{message}</span> : null} */}
//       <div className="mb-6" />
//       <div className="flex items-center justify-between">
//         <Back action={this.props.back} />
//         <Next action={this.nextClick} />
//       </div>
//     </>
//     )
//   }
// }

// export default InsuranceInfo