import React, { useContext } from "react"
import TextField from "./inputfields/TextField"
import { Context } from "./store"
import AddressTextField from "./AddressField/AddressTextField"
import Next from "./buttons/Next"
import Back from "./buttons/Back"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import * as yup from "yup"
 
const schema = yup.object().shape({
  // name: yup.string().required("Name is required"),
  // address: yup.string().required("Address is required"),
  // phone: yup.string().required("Phone is required"),
  email: yup.string().required("Email is required").matches(new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),"Invalid Format "
  ),
})

export default function BillingInformation({ back, next }) {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
    mode: "onChange",
  })

 const { store, dispatch } = useContext(Context)
  const onSubmit = data => {
    dispatch({ type: "addPaymentDetails", data })
    next()
  }

  // const sameAsCompany = () => {
  //   dispatch({ type: "setSameAsMyCompany" })
  // }

  const handleSelectedAddress = address => {
    dispatch({
      type: "updateStreetCityStateZipPayment",
      data: address,
    })
  }
  const onTel = (event,type) => {
    console.log(store.carrier,event.target.value,type)
    dispatch({
     type: "verifyTaxPhone",
     data: {"value": event.target.value,"type":type},
   })
}
  console.log(store.carrier)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="relative">
        <TextField
          label="Name"
          name="name"
          value={store.carrier.PaymentMethod==="Factoring Agent"?store.carrier.FactoringCompany.Name:store.carrier.CheckInfo.Name}
          registerFn={register}
          error={errors.name}
        />
        {/* <div className="absolute right-0 l-0" style={{ top: "-10px" }}>
          <button
            type="button"
            className="bg-black text-white text-xs py-1 px-4 rounded-full outline-none"
            onClick={sameAsCompany}
          >
            Same as my Company
          </button>
        </div> */}
      </div>

      <AddressTextField
        name="address"
        label="Address"
        addressType="address"
        placeholder=""
        error={errors.address}
        value={store.fullPaymentAddress}
        onSelectResult={handleSelectedAddress}
        registerFn={register}
      />

      <div className="flex flex-wrap -mx-3">
        <div className="w-full md:w-1/2 px-3 md:mb-0">
       
        <label
          className="block text-gray-900 text-sm font-bold mt-2 mb-2"
          htmlFor={"phone"}
        >
          {"Phone"}
        </label> 
        <InputMask
            mask="(999) 999-9999"
            type="tel"
            id="Phone"
            title="Phone"
            placeholder="(123) 456-7890"
            className="h-12 border rounded w-full px-3 text-gray-700 focus:outline-none focus:shadow-outline text-2xl pr-10 bg-gray-200"
            value={store.carrier.PaymentMethod==="Factoring Agent"?store.carrier.FactoringCompany.PhysicalAddress.Phone[0]:store.carrier.CheckInfo.Address.Phone[0]}
            onChange={(e) => onTel(e,"Phone")}
            error={errors.phone}
            inputRef={register}
            name="phone"
          ></InputMask>
        
        </div>
        <div className="w-full md:w-1/2 px-3 md:mb-0">
        <label
          className="block text-gray-900 text-sm font-bold mt-2 mb-2"
          htmlFor={"fax"}
        >
          {"Fax"}
        </label>
        <InputMask
            mask="(999) 999-9999"
            type="tel"
            id="Fax"
            title="Fax"
            placeholder="(123) 456-7890"
            className="h-12 border rounded w-full px-3 text-gray-700 focus:outline-none focus:shadow-outline text-2xl pr-10 bg-gray-200"
            value={store.carrier.PaymentMethod==="Factoring Agent"?store.carrier.FactoringCompany.PhysicalAddress.Fax:store.carrier.CheckInfo.Address.Fax}
            onChange={(e) => onTel(e,"Fax")}
            error={errors.phone}
            inputRef={register}
            name="fax"
          ></InputMask>
        </div>
      </div>
      <TextField
        type="email"
        label="Email*"
        name="email"
        value={store.carrier.PaymentMethod==="Factoring Agent"?store.carrier.FactoringCompany.PhysicalAddress.Email[0]:store.carrier.CheckInfo.Address.Email[0]}
        registerFn={register}
        error={errors.email}
      />
      <div className="mb-6"></div>
      <div className="flex items-center justify-between">
        <Back action={back} />
        <Next />
      </div>
    </form>
  )
}
