import React from "react"
import "./Table.css"

export default class Table extends React.Component {
  getKeys = () => {
    //console.log(this.props)
    return Object.keys(this.props.data[0])
  }

  getHeader = () => {
    var keys = this.getKeys()
    return keys.map((key, index) => {
      return <th key={key}>{key.toUpperCase()}</th>
    })
  }

  getRowsData = () => {
    var items = this.props.data
    var keys = this.getKeys()
    var fun = this.props.handleTable
    return items.map((row, index) => {
      //console.log("INdex: ",index)
      return (
        <tr key={index}>
          <RenderRow
            table={items}
            fun={fun}
            count={index}
            data={row}
            keys={keys}
          />
        </tr>
      )
    })
  }

  render() {
    return (
      <div>
        <table>
          <thead>
            <tr>{this.getHeader()}</tr>
          </thead>
          <tbody>{this.getRowsData()}</tbody>
        </table>
      </div>
    )
  }
}
const RenderRow = props => {
  let length = props.keys.length

if(window.innerWidth<768&&props.keys[0]==="AttachmentPath"){
  return props.keys.map((key, index) => {
    if (index === 0)
      return (
        <td key={`td${index}`}>
          
          <div
            key={`div${index}`}
            className="pointer"
         
          >     
            {props.data[key]}
            <svg
            style={{float: 'right'}}
              id="delete"
              onClick={() => {
                props.fun(props.count)
              }}
              height="24"
              viewBox="0 0 515.556 515.556"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m64.444 451.111c0 35.526 28.902 64.444 64.444 64.444h257.778c35.542 0 64.444-28.918 64.444-64.444v-322.222h-386.666z" />
              <path d="m322.222 32.222v-32.222h-128.889v32.222h-161.111v64.444h451.111v-64.444z" />
            </svg>
          </div>
        </td>
      )
  else return null
  })}else{
     return props.keys.map((key, index) => {
    if (index === length - 1)
      return (
        <td key={`td${index}`}>
          
          <div
            key={`div${index}`}
            className="pointer"
         
          >     
            {props.data[key]}
            <svg
            style={{float: 'right'}}
              id="delete"
              onClick={() => {
                props.fun(props.count)
              }}
              height="24"
              viewBox="0 0 515.556 515.556"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m64.444 451.111c0 35.526 28.902 64.444 64.444 64.444h257.778c35.542 0 64.444-28.918 64.444-64.444v-322.222h-386.666z" />
              <path d="m322.222 32.222v-32.222h-128.889v32.222h-161.111v64.444h451.111v-64.444z" />
            </svg>
          </div>
        </td>
      )
    else return <td key={`td${index}`}>{props.data[key]}</td>})
  }
    
}
