import React, { useContext } from "react"
import DirectDeposit from "./DirectDeposit"
import BillingInformation from "./BillingInformation"
import { Context } from "./store"

export default function Payment({ currentStep, back, next }) {
  const { store, dispatch } = useContext(Context)
 
  if (currentStep !== 7) {
    return null
  }

  const handleChange = e => {
    dispatch({ type: "setPaymentMethod", data: e.target.value })

  }

// const handleQuickPay = e => {
//     dispatch({ type: "setQuickPay", data: e.target.value })
//     console.log(store.carrier.RequireQuickPay)
//   }


  let subView

  switch (store.carrier.PaymentMethod) {
    case "Factoring Agent":
      subView = <BillingInformation back={back} next={next} />
      break
    case "Direct Deposit":
      subView = <DirectDeposit back={back} next={next} />
      break
    case "E-Check":
      subView = <BillingInformation back={back} next={next} />
      break
    default:
      subView = ""
  }

  return (
    <>
      <h2 className="text-gray-700 text-xl mb-4">Payment</h2>
   {/* <div className="mt-6"></div>

      <p className="font-bold mb-3">Require Quick Pay? *</p>

      <label className="inline text-gray-900 mr-5">
        <input
          className="mr-2 leading-tight"
          type="radio"
          name="RequireQuickPay"
          value={"Yes"}
          checked={store.carrier.RequireQuickPay === true}
          onChange={handleQuickPay}
        />
        <span className="text-sm">Yes</span>
      </label>

      <label className="inline text-gray-900 mr-5">
        <input
          className="mr-2 leading-tight"
          type="radio"
          value={"No"}
          name="RequireQuickPay"
          checked={store.carrier.RequireQuickPay === false}
          onChange={handleQuickPay}
        />
        <span className="text-sm">No</span>
      </label> */}
      <div className="mt-6"></div>

      <p className="font-bold mb-3">How will settlements be paid? *</p>

      <label className="inline text-gray-900 mr-5">
        <input
          className="mr-2 leading-tight"
          type="radio"
          name="settlementMethod"
          value="Factoring Agent"
          checked={store.carrier.PaymentMethod === "Factoring Agent"}
          onChange={handleChange}
        />
        <span className="text-sm">Factoring Agent</span>
      </label>

      <label className="inline text-gray-900 mr-5">
        <input
          className="mr-2 leading-tight"
          type="radio"
          value="Direct Deposit"
          name="settlementMethod"
          checked={store.carrier.PaymentMethod === "Direct Deposit"}
          onChange={handleChange}
        />
        <span className="text-sm">Direct Deposit</span>
      </label>
      <label className="inline text-gray-900">
        <input
          className="mr-2 leading-tight"
          type="radio"
          value="E-Check"
          name="settlementMethod"
          checked={store.carrier.PaymentMethod === "E-Check"}
          onChange={handleChange}
        />
        <span className="text-sm">E-Check</span>
      </label>

      <div className="mb-6"></div>
      {subView}
    </>
  )
}
