import React, { useContext } from "react"
import Checkbox from "./inputfields/Checkbox"
import Next from "./buttons/Next"
import Back from "./buttons/Back"
import TextField from "./inputfields/TextField"
import { useForm } from "react-hook-form"
import { Context } from "./store"
import * as yup from "yup"
const schema = yup.object().shape({
  name: yup.string().required("Name is required"), 
  title: yup.string().required("Title is required")
 })

export default function CarrierAgreement({ currentStep, back, next  }) {
  const { register, handleSubmit, errors, reset} = useForm({
    validationSchema: schema,
    mode: "onChange",
  })
  const {store, dispatch } = useContext(Context)

  if (currentStep !== 9) {
    return null
  }
  const onSubmit = data => {  
    console.log("submit",data)
    dispatch({ type: "setCarrierAgreementInfo",data})    

        reset();
        next();
    
      }
  // const handle = e => {
  //   let name=document.getElementById("name").value;
  //   let title= document.getElementById("title").value; 
  //   //let agree= document.getElementsByName("check")[0].checked;
  //   let data= {"name":name ,"title": title}
  //   console.log("checkbox: ",data)
  //   dispatch({ type: "setCarrierAgreementInfo",data:data})    
  //   next();
  // }
  const handleCAUserAgree = e => {
    dispatch({ type: "setCarrierAgreeWithTerms",data:e.target.checked})    
  }
  console.log("_FORM_DATA: ",store)


  return (
    <>
      <h2 className="text-gray-700 text-xl mb-4">
       Carrier Agreement
      </h2>
      <div style={{ overflow: "auto", maxHeight: "600px", backgroundColor:"#f7fafc" }} >
        <p>
          <strong>This Carrier Agreement (&ldquo;CA&rdquo;)</strong> is made and
          entered into between Archerhub, whose principal office is located at
          1860 Blake St. Suite 620, Denver, CO 80202 (&ldquo;ARCHERHUB&rdquo;)
          and &ldquo;CARRIER&rdquo; (as provided for under the
          &ldquo;E-Signature&rdquo; section, &ldquo;Account Name&rdquo;)
          (collectively &ldquo;Parties&rdquo;).
        </p>
        <br />
        <p>
          <strong>RECITALS</strong>
          <br />
          WHEREAS, ARCHERHUB is a carrier duly authorized to transport or to
          arrange for the transportation of freight; and, WHEREAS, ARCHERHUB has
          received approval from certain of its customers
          (&ldquo;Customer(s)&rdquo;) to tender freight to various
          transportation service providers for outsourced services; and,
          WHEREAS, CARRIER is duly authorized by appropriate federal, state, or
          provincial agency or agencies of either the United States or Canada,
          or both, to provide transportation services (proof of such authority
          or authorities shall be provided to ARCHERHUB by CARRIER); and,
          WHEREAS, CARRIER wishes to supply its services to ARCHERHUB for
          outsourced services; NOW, THEREFORE, ARCHERHUB and CARRIER hereby
          agree to the following terms:
        </p>
        <br />
        <ol className="reset-ol-ul list-decimal">
          <li>
            <strong>CA and Other Documents</strong>
            <br />
            <br />
            <strong>
              1.1 Applicability of 49 U.S.C. &sect;14101 and Priority of
              Documents.
            </strong>
            <br />
            This CA is entered into in accordance with 49 U.S.C. &sect;14101.
            Except as may be otherwise provided herein or in any addenda hereto,
            this CA, and the services provided hereunder, shall be subject to
            Titles 49 of the United States Code (&ldquo;U.S.C.&rdquo;) and of
            the Code of Federal Regulations (&ldquo;CFR&rdquo;). Pursuant to 49
            U.S.C. &sect;14101(b)(1), however, ARCHERHUB and CARRIER hereby
            waive any and all rights and remedies provided under that part to
            the extent they are in conflict with the terms of this ARCHERHUB and
            its addenda. Unless expressly stated otherwise in this CA or its
            addenda, this CA and any addenda shall govern the relationship
            between ARCHERHUB and CARRIER and all other documents shall be
            subordinate to them. In the event of a conflict between this CA and
            an addendum or amendment the term of the addendum or amendment shall
            apply. This CA and any attachments, exhibits, amendments or addenda
            hereto shall be the entire agreement between the parties and
            supersedes and cancels any and all prior written or oral agreements
            and understandings regarding the subject matter of this CA between
            the Parties. No modifications or amendments <br />
            to this CA shall be binding upon ARCHERHUB unless initialed and
            signed by a ARCHERHUB authorized representative. This CA may not be
            assigned by CARRIER in whole or in part without the prior written
            agreement of ARCHERHUB.
            <br />
            <br />
            <strong>
              1.2 Contract Carriage/Standard Bill of Lading Terms.
            </strong>
            <br />
            Except as otherwise provided in this CA and any addenda hereto,
            whether or not CARRIER is authorized to operate, or does operate, as
            a common carrier, each and every shipment tendered to CARRIER by
            ARCHERHUB during the term of this CA shall be deemed to be a tender
            to CARRIER as a motor contract carrier. All shipments shall be
            deemed to have been made on the Uniform Straight Bill of Lading
            despite the bill of lading form that is supplied for any individual
            shipment.
            <br />
            <br />
            <strong>1.3 Load Tender Form.</strong> <br />
            ARCHERHUB shall submit load tenders (&ldquo;Tender&rdquo;) to
            CARRIER on the behalf of Customers. The transportation services
            provided by CARRIER shall be designed to meet the distinct transit,
            service and pricing requirements of ARCHERHUB and Customers, as set
            out in each Tender. The origins and destinations to be served, the
            commodities to be transported, and the rates and charges applicable
            to the transportation services shall be included in the Tender.
            Acceptance of the Tender and its terms shall be made by signature
            and return by CARRIER or by actual acceptance of the tendered
            shipment. All Tenders shall be deemed binding attachments to this CA
            and shall be controlled hereby. Driving directions to or from a
            customer&rsquo;s location may be communicated to the CARRIER via the
            load tender document or other specified means. Directions are for
            informational purposes only. It shall be the CARRIER&rsquo;s sole
            responsibility to insure the directions are appropriate and
            ARCHERHUB makes no guarantee with respect to specified routes and
            the compatibility of those routes with regards to any type of
            equipment.
            <br />
            <br />
          </li>
          <li>
            <strong>Relationship of the Parties.</strong>
            <br />
            <br />
            <strong>2.1 CARRIER Status.</strong> <br />
            CARRIER shall perform any and all services hereunder as an
            independent contractor. No provision of this CA or any act or
            omission of either party shall be construed for any purpose to
            express or imply a joint venture, partnership, principal/agent,
            fiduciary, parent/subsidiary, employer/employee relationship or
            other such relationship implying a lack of independent operation by
            CARRIER. CARRIER shall provide sole supervision and liability for
            and shall have exclusive control over the operations of its
            personnel, contractors, subcontractors, and other agents, as well as
            any and all other vehicles, equipment and property, whether tangible
            or intangible, under the control of CARRIER. ARCHERHUB shall have no
            right to discipline or direct the performance of any personnel,
            contractors, subcontractors, and other agent of CARRIER. CARRIER
            agrees and warrants that it shall at no time and for no purpose
            represent any affiliation with ARCHERHUB other than that of an
            independent contractor and shall not, other than in that capacity,
            set itself out as having any power or authority to act on the behalf
            of or to bind ARCHERHUB. CARRIER shall at no time knowingly or
            willfully take any action that in any way reflects negatively on
            ARCHERHUB.
            <br />
            <br />
            <strong>2.2 CARRIER Personnel.</strong> <br />
            CARRIER assumes full responsibility and liability for the payment of
            the following listed items and agrees to comply with the laws, and
            any rules and regulations promulgated thereunder, by any applicable
            federal, state, or provincial jurisdiction (and, when applicable,
            shall provide periodic proof of such compliance): payroll taxes or
            contributions to taxes for unemployment insurance, old age pensions,
            worker&rsquo;s compensation, social security, or other related
            protection with respect to persons engaged in the performance of
            said transportation services. ARCHERHUB shall not be responsible for
            any act or omission of any personnel,contractors, subcontractors or
            other agents of CARRIER. CARRIER shall fully indemnify and hold
            ARCHERHUB harmless under the terms of indemnity, herein, for any
            claim brought against ARCHERHUB for any such employment obligation.
            <br />
            <br />
          </li>
          <li>
            <strong>Non-exclusivity.&nbsp;</strong> <br />
            Unless otherwise agreed through a written amendment hereto, CARRIER
            and ARCHERHUB agree that this CA is nonexclusive and that ARCHERHUB
            may utilize other Carriers to fulfill the transportation needs of
            the customers of ARCHERHUB. In addition, CARRIER shall be free to
            accept freight from customers other than ARCHERHUB. CARRIER is
            further notified, understands and agrees that all loads tendered are
            on an &ldquo;as needed&rdquo; basis and nothing contained herein,
            nor any tendered are on an &ldquo;as needed&rdquo; basis and nothing
            contained herein, nor any guarantee any volume of any kind or
            constitute any commitment whatsoever to utilize the services of
            CARRIER.
            <br />
            <br />
          </li>
          <li>
            <strong>CARRIER Service Requirements.</strong>
            <br />
            <br />
            <strong>&nbsp;4.1 Communications with ARCHERHUB.&nbsp;</strong>
            CARRIER shall be responsible to give periodic updates on the status
            of any shipment for which it accepts tender. Such communication
            shall be to advise ARCHERHUB of information such as, but not limited
            to: CARRIER&rsquo;s arrival for loading and unloading at each stop;
            confirmation of completion of loading and unloading at each stop;
            any overage, shortage or damage information relating to cargo;
            notice of the location where any trailer is spotted, if ARCHERHUB or
            customer equipment is used; periodic check calls, and accident
            reporting. The method(s) CARRIER uses to provide such information to
            ARCHERHUB shall be approved by ARCHERHUB and may include: EDI, ELT,
            DraynetTM (a proprietary program designed by ARCHERHUB), facsimile,
            Internet, e-mail, telephone, or other agreed means. CARRIER
            acknowledges receipt of and agrees to follow the Archerhub Cargo
            Claims and Accident Reporting procedures attached hereto as
            Attachment A.
            <br />
            <br />
            <strong>4.2 Covenant Not to Back Solicit</strong>
            <br />
            <br />
            <strong>4.2.1 No Back-Solicitation.&nbsp;</strong> <br />
            Except where mutual customers exist, CARRIER hereby agrees that
            neither it nor its independent contractors, subcontractors, owner
            operators or agents will, while this CA is effective and for a
            period of one (1) year following termination of this CA, directly or
            indirectly solicit or accept traffic
            <br />
            from customers, shippers, or consignees initially introduced to it
            by ARCHERHUB or from customer-specific shipping origins and
            destination point-to-point lane combinations that were first
            tendered to CARRIER by ARCHERHUB ( &ldquo;ARCHERHUB Traffic&rdquo;).
            The term &ldquo;mutual customers&rdquo; includes any customers with
            whom CARRIER has provided transportation services within 6 months
            prior to CARRIER&rsquo;s execution of this CA and who also do
            business with ARCHERHUB.
            <br />
            <br />
            <strong>4.2.2 Penalty for Breach&nbsp;</strong> <br />
            If CARRIER, its independent contractors, subcontractors, owner
            operators or agents at any time while this CA is effective or within
            one (1) year following termination of this CA, directly or
            indirectly solicits or accepts ARCHERHUB Traffic as defined herein,
            ARCHERHUB shall be entitled to a <br />
            commission of forty (40%) of the line haul revenue received by
            CARRIER on such ARCHERHUB Traffic.
            <br />
            <br />
            <strong>4.3 Prohibition Against Brokering</strong> <br />
            Except as may be otherwise provided in this CA or any addenda
            hereto, CARRIER hereby agrees and acknowledges that it is strictly
            prohibited from using other motor carriers, or brokers, or
            &ldquo;substituted services&rdquo; for the services to be performed
            hereunder for ARCHERHUB and Customers. Trip leasing shall not be
            allowed hereunder. Should CARRIER violate any of the prohibitions
            contained in this section, CARRIER agrees that it shall be fully
            liable for any payment due to such motor carriers or brokers or for
            substituted services. CARRIER further agrees that it shall be fully
            liable for any loss, damage or delay to the Commodities of
            ARCHERHUB&rsquo;s Customer(s), and any and all liabilities, losses,
            claims, costs, damages, and injuries (including death) incurred in
            transit to the same extent that CARRIER would be liable if it
            performed the transportation directly. Should CARRIER use other
            motor carriers &ldquo;substituted services&rdquo; or broker out any
            movement tendered by ARCHERHUB, such action shall constitute a
            breach of this CA.
            <br />
            <br />
            Each incident of such breach shall subject the CARRIER to immediate
            termination of this CA, subject to the sole discretion of ARCHERHUB.
            Such penalty shall not be a limitation of remedies available to
            ARCHERHUB for any such breach.
            <br />
            <br />
            <strong>4.4 Hazardous Materials</strong> <br />
            <strong>&nbsp;</strong>ARCHERHUB may tender hazardous material
            shipments to CARRIER if CARRIER meets all requirements established
            in Attachment B to this CA. CARRIER hereby acknowledges receipt of
            and agrees to be bound by the Extra Requirements for Hazardous
            Materials Shipments attached hereto as Attachment B. CARRIER shall
            be responsible to inspect all paperwork tendered by a shipper to
            insure its accuracy and the commodity being shipped. In the event
            ARCHERHUB tenders a load to CARRIER that is not designated as
            containing hazardous materials and upon arrival at a shipper&rsquo;s
            location the CARRIER is informed or discovers that that shipper
            intends to load hazardous materials, or similar other commodities
            for which special permits or authority is required and for which
            CARRIER has not been approved to transport for ARCHERHUB, then
            CARRIER shall be responsible to notify ARCHERHUB and to reject any
            such shipment. In the event CARRIER fails to reject a shipment
            containing hazardous material then CARRIER shall indemnify and hold
            ARCHERHUB harmless form all fines, penalties, claims, cleanup,
            environmental harm or any other associated costs that arise as a
            result of transporting such load. If CARRIER properly notifies
            ARCHERHUB of the discovery of a hazardous material shipment and can
            provide a current Hazardous Materials Certificate of Registration
            issued by the US DOT and, at its expense, insurance as required by
            49 C.F.R. 387.9 and CARRIER wishes to transport the hazardous
            material shipment, then CARRIER and ARCHERHUB may negotiate for such
            services on a load-by-load basis.
            <br />
            <br />
            <strong>4.5 Equipment</strong> <br />
            CARRIER shall ensure that the transportation services shall be
            performed with equipment which is in good order, condition and
            repair and which meets with all applicable federal and state laws,
            rules and regulations, including but not limited to those of the
            DOT. CARRIER acknowledges that ARCHERHUB deals in commodities
            requiring sanitary equipment that is in compliance with local, state
            and federal statutes and regulations. CARRIER agrees that it will
            provide only equipment which is in compliance with such statutes and
            regulations, and specifically agrees that no equipment will be
            supplied for transportation of shipments hereunder which has ever
            been utilized to haul garbage, industrial, municipal or residential
            refuse, solid or liquid hazardous waste or any similar materials.
            <br />
            <br />
            <strong>4.6 Receipts and Bills of Lading</strong> <br />
            Each shipment hereunder shall be evidenced by a receipt, which shall
            be signed by an agent or employee of CARRIER and showing the kind
            and quantity of product received by CARRIER at origin, the name of
            CARRIER&rsquo;s employee and naming CARRIER as the carrier of the
            shipment. Such receipt shall be presumptive evidence of
            CARRIER&rsquo;s receipt of such shipment in good order and condition
            unless the contents of such shipment are not readily observable or
            as may otherwise be noted on the face of such receipt. If the
            CARRIER, ARCHERHUB or Customer elects to use a bill of lading,
            manifest or other documents, any terms, conditions and provisions of
            such bill of lading, manifest or other documents shall not operate
            as an amendment to this CA, but shall be subject and subordinate to
            the terms, conditions and provisions of this CA and, in the event
            they conflict with this CA, the terms, conditions and provisions of
            this CA shall prevail and take precedence.
            <br />
            <br />
            <strong>
              4.7 Representations and Warranties Regarding Drivers and Equipment
            </strong>
            <br />
            CARRIER represents and warrants that its drivers and equipment will
            meet the following requirements: <br />
            a. Drivers will be qualified by CARRIER in accordance with 49 C.F.R.
            391, 392 and 380 (as applicable);
            <br />
            b. Drivers will have completed controlled substance and/or alcohol
            testing as required by 49 C.F.R. 382; <br />
            c. Drivers will have no more than 3 moving violations in the past 3
            years (from conviction date);
            <br />
            d. Drivers will have no DWI or DUI convictions in the past 5 years
            or a current charge pending (from conviction date); <br />
            e. Drivers will have no careless or reckless driving violations in
            the past 5 years (from conviction date); <br />
            f. Drivers will have a valid CDL of the proper class with necessary
            endorsements; <br />
            g. Drivers will be properly rested and in compliance with 49 C.F.R.
            395; <br />
            h. CARRIER acknowledges that it satisfies its duty to monitor driver
            hours of service compliance in accordance with FMCSR; <br />
            i. Equipment provided by CARRIER will meet the requirements of 49
            C.F.R. 393 and 396; <br />
            j. Equipment will be inspected by the driver and determined to be
            free of defects likely to result in an accident or mechanical
            breakdown; <br />
            k.CARRIER possesses all necessary authority, permits and other
            documents required for movement of the freight tendered.
            <br />
            <br />
            <strong>4.8 US/CANADA or US/MEXICO BORDER CROSSINGS</strong> <br />
            The CARRIER in possession of a load when it crosses an international
            border is solely responsible for ensuring that they have proper
            paperwork and clearances to move the load across a border. Any
            penalties or fines imposed by Customs for failure to do this
            correctly are the responsibility of the CARRIER, and not ARCHERHUB.
            <br />
            <br />
          </li>
          <li>
            <strong>CARRIER Qualification</strong>
            <br />
            <br />
            <strong>5.1 Authority</strong> <br />
            As stated in the Recitals, CARRIER shall provide proof that it is
            duly authorized to perform the specified services it is to provide
            to ARCHERHUB. CARRIER shall not transport any tendered shipment and
            shall notify ARCHERHUB immediately in the event all or any part of
            such required authority is cancelled. If CARRIER performs any
            services for which it does not or no longer possesses proper
            authority, then it shall forfeit the right to payment for such
            services and shall hold ARCHERHUB and Customers harmless under the
            terms of Indemnification herein.
            <br />
            <br />
            <strong>5.2 Safety Rating&nbsp;</strong> <br />
            CARRIER shall provide ARCHERHUB with a copy of the most recent
            safety rating for CARRIER. If CARRIER is operating domiciled in
            California then carrier shall also provide a copy of the most recent
            BIT inspection for CARRIER. Unless otherwise approved, the safety
            rating for CARRIER must be Satisfactory. CARRIER shall immediately
            notify ARCHERHUB if CARRIER&rsquo;s safety rating becomes
            Conditional or Unsatisfactory. In the event of a Conditional or
            Unsatisfactory rating, ARCHERHUB may suspend all tender of all
            shipments until such rating is again acceptable and ARCHERHUB may
            exercise the right to immediate termination of the CA.
            <br />
            <br />
            <strong>5.3 &nbsp;Insurance</strong>
            <br />
            <br />
            <strong>5.3.1 Procurement</strong> <br />
            CARRIER shall procure and maintain, at no cost to ARCHERHUB, and
            with reputable and financially responsible insurance underwriters
            with an AM best rating of &ldquo;B+&rdquo; or better, the following
            insurance coverage: <br />
            <strong>5.3.1.1</strong>
            Cargo liability insurance, whether All-Risk or Broad Form, covering
            risks for loss of or damage to shipments, in the minimum amount not
            less than One Hundred Thousand Dollars ($100,000) per shipment;
            <br />
            <strong>5.3.1.2</strong> Automobile liability insurance for bodily
            injury (including injury resulting in death) and loss of or damage
            to property, in the amount not less than One Million Dollars
            ($1,000,000) combined single limit per occurrence, and extending to
            &ldquo;Any Auto&rdquo; or &ldquo;All Owned, Hired and Non-Owned
            Autos.&rdquo; &ldquo;Scheduled Autos&rdquo; is acceptable providing
            the specific vehicle to be utilized is named on the carrier&rsquo;s
            insurance schedule. <br />
            <strong>5.3.1.3</strong>
            Workers&rsquo; compensation insurance as required by applicable law
            (AM Best rating of &ldquo;B&rdquo; or better is acceptable for
            workers&rsquo; compensation coverage); <br />
            <strong>5.3.1.4</strong> Any additional insurance requirements under
            any and all applicable United States, Canada and Mexico federal,
            state, provincial and local laws, regulations and rules.
            <br />
            <br />
            <strong>5.3.2 Certification</strong> <br />
            CARRIER shall furnish to ARCHERHUB written certificates showing that
            the above insurance has been procured and is being maintained, the
            amount of any deductibles, self-insured retentions or the like
            applying to each policy, and specifying the name of the insurer,
            insurance underwriter, producer or issuing agency, the policy number
            or numbers, and the expiration date or dates. ARCHERHUB shall be a
            certificate holder on the policies described above and, as to each
            policy, in the event of cancellation or material modifications of
            any policy, written notice shall be given to ARCHERHUB at least
            thirty (30) days prior to the effective date of such cancellation or
            modification. Should CARRIER&rsquo;s insurance be cancelled at any
            time for any reason whatsoever, then CARRIER must notify ARCHERHUB
            immediately and CARRIER shall not accept any further shipments.
            <br />
            <br />
            <strong>5.3.3 Applies to Independent Contractors</strong>
            <br />
            Insurance provided by CARRIER hereunder shall also completely and
            unconditionally apply and extend to and cover losses or liabilities
            occasioned by any and all independent contractors, subcontractors,
            or owner operators of any tier utilized by CARRIER to transport
            freight tendered by ARCHERHUB. Certificates of insurance provided to
            ARCHERHUB shall specify such application to and coverage of such
            sub/independent contractors and owner operators.
            <br />
            <br />
            <strong>5.3.4 Exclusions and Restrictions</strong> <br />
            The coverage provided under the cargo policy and other policies
            required herein shall have no exclusions or restrictions of any type
            that would forseeably preclude coverage relating to loss and damage
            claims, including reasonable cargo claims. CARRIER hereby agrees to
            provide directly to <br />
            ARCHERHUB and grants permission to and requires its insurance
            producers and insurers to provide directly to ARCHERHUB upon
            ARCHERHUB&rsquo;s request a copy of all insurance policies of every
            coverage type, including a copy of all exclusions from the cargo
            policy. CARRIER&rsquo;s cargo insurance policies shall be primary
            and not contingent and shall not exclude coverage for infidelity,
            fraud, dishonesty, or criminal acts of CARRIER or of the personnel
            (including officers and directors), contractors, subcontractors,
            owner operators or other agents of CARRIER. If said policy contains
            such exclusions, CARRIER shall obtain and furnish a policy extension
            or surety bond providing such coverage to the satisfaction of
            ARCHERHUB.
            <br />
            <br />
            <strong>5.3.5 Premiums and Deductibles</strong> <br />
            CARRIER shall be responsible for all premiums and deductibles, and
            not withstanding any provision of the paragraph, it is understood
            and agreed that the liability assumed by CARRIER hereunder shall not
            be limited to the insurance coverage stipulated herein. No policy
            shall have a <br />
            deductible, retention, self-insured retention or the like in excess
            of $10,000.
            <br />
            <br />
            <strong>5.3.6 Failure to Comply with Requirements</strong>
            <br />
            Breach. CARRIER agrees that breach of the provisions of this Article
            5 constitutes a material breach of this CA whereupon ARCHERHUB may,
            upon notice, immediately terminate this CA.
            <br />
            <br />
          </li>
          <li>
            <strong>Payment</strong>
            <br />
            <br />
            <strong>6.1 Payment Terms</strong> <br />
            CARRIER shall make a best effort to invoice ARCHERHUB within
            fourteen (14) days of a shipment&rsquo;s delivery. ARCHERHUB shall
            pay CARRIER for its services pursuant to the terms in the Tender, or
            as otherwise agreed in writing, and upon receipt by ARCHERHUB from
            CARRIER of (1) the original bill of lading or shipping order, (2)
            delivery receipt (such bills of lading, shipping orders and delivery
            receipts shall be referred to as &ldquo;Shipment Documents&rdquo;),
            (3) a copy of the Tender and (4) CARRIER&rsquo;s invoice. CARRIER
            shall write the ARCHERHUB load number on each such document for
            purposes of identification and cross-referencing. Failure by CARRIER
            to provide any Shipment Documents may, within the sole discretion of
            and to the satisfaction of ARCHERHUB, be grounds for withholding or
            delaying payment for services related to the subject shipment.
            ARCHERHUB shall have no responsibility to pay any invoice received
            more than one hundred eighty (180) days from the date of a
            shipment&rsquo;s delivery. The parties agree that any monies owed by
            ARCHERHUB to CARRIER may be withheld or offset by any claims or
            other amounts that CARRIER may owe to ARCHERHUB, including, but not
            limited to for any over-payments or payments made in error by
            ARCHERHUB. In addition, in the event that CARRIER ceases on-going
            operations, has its liability or cargo insurance coverage cancelled
            or revoked, otherwise breaches this CA, is insolvent, files a
            petition in bankruptcy, or if a trustee is appointed to liquidate
            the assets of CARRIER, the parties agree that any monies owed by
            ARCHERHUB may be withheld and offset by any claims or other amounts
            that may be owed by CARRIER to ARCHERHUB.
            <br />
            <br />
            <strong>6.2 Responsibility for Payment</strong> <br />
            CARRIER agrees that it shall look solely to ARCHERHUB for payment
            for any services rendered hereunder, and shall not, in any event
            whatsoever, contact the Customer, Shipper, Consignor or Consignee
            regarding payment of freight bills without the prior, express
            written consent of ARCHERHUB. In the event CARRIER or any of its
            independent contractors, subcontractors, owner operators or other
            agents violates this provision, then CARRIER shall be subject to a
            penalty of $500 per occurrence.
            <br />
            <br />
          </li>
          <li>
            <strong>No Lien</strong> <br />
            CARRIER shall have no lien and CARRIER hereby expressly waives its
            right to any lien on any cargo or other property of ARCHERHUB or its
            Customers. As such, CARRIER shall not withhold any freight due to a
            dispute with ARCHERHUB regarding any freight charges.
            <br />
            <br />
          </li>
          <li>
            <strong>Cargo Liability and Claims</strong>
            <br />
            <br />
            <strong>8.1 Liability</strong> <br />
            CARRIER shall be liable to ARCHERHUB, and its Customers, as set
            forth in Title 49 U.S.C. &sect;14706 (the Carmack Amendment) and
            applicable common law, for any and all loss of the shipment due to
            loss of or damage (injury) to cargo transported pursuant to this CA,
            occurring while in the care, custody or control of CARRIER
            irrespective of where the loss, damage or delay occurs (in
            interstate, foreign, intrastate, domestic or trans-border commerce
            originating at a point in and/or destined to a point in the United
            States, Canada, or Mexico). Any attempt by CARRIER to limit their
            liability or amend this CA by provisions contained in any bill of
            lading, delivery receipt or tariff (whether filed, published or
            independently determined), whether purported to be incorporated by
            reference into this CA by an attachment or otherwise shall be deemed
            null and void.
            <br />
            <br />
            <strong>8.2 Claims</strong> <br />
            ARCHERHUB shall act as the third-party administrator for any claim
            brought by Customers. CARRIER hereby acknowledges receipt of and
            agrees to follow the Archerhub Cargo Claims and Accident Reporting
            Procedures attached hereto as Attachment A. In the event that the
            receiver refuses to accept a load for delivery or any portion of a
            load, CARRIER agrees to cooperate with the ARCHERHUB in
            investigating and resolving the delivery of the load at issue,
            including, but not limited to, waiting at the receiver or other
            designated location until an adjuster arrives to inspect the load,
            the receiver is persuaded to accept the load, and/or the shipper
            provides other directions regarding the disposition of the load.
            CARRIER agrees to comply with any reasonable requests of ARCHERHUB,
            the shipper or receiver regarding the disposition of the load,
            including delivery of the <br />
            load to another specified location. If CARRIER fails to cooperate,
            CARRIER shall be liable to ARCHERHUB for any additional costs or
            expenses incurred by ARCHERHUB in the investigation and resolution
            of the load at issue, including, but not limited to costs and
            expenses associated with employing the services of another carrier
            to complete delivery and disposition of the load, CARRIER agrees
            that ARCHERHUB shall be entitle to withhold and offset any such
            amounts against any amounts owed by ARCHERHUB to CARRIER. Except as
            specifically set forth to the contrary herein, all claims for
            overage, shortage, loss and damage and any salvage arising therefrom
            shall be submitted to CARRIER and handled and processed in
            accordance with 49 C.F.R. Part 370. CARRIER shall acknowledge
            receipt of all such claims within thirty (30) days, and shall settle
            all claims within (120) days of receipt.
            <br />
            <br />
            <strong>8.3 Salvage</strong> <br />
            In the event branded or labeled goods are damaged, ARCHERHUB (in
            conjunction with Customer) may determine, in its sole discretion,
            whether the goods may be salvaged and, if salvageable, the value of
            such salvage. CARRIER shall not sell, otherwise dispose of, or
            permit the sale, disposal or salvage of any goods bearing any trade
            name, trademark, logo or service mark without first, obtaining the
            written consent of ARCHERHUB and then removing all such trade names,
            trademarks, logos or service marks prior to such sale or disposal.
            <br />
            <br />
            <strong>8.4 Time Limits&nbsp;</strong> <br />
            Except as otherwise set out in this Article 8, the time limits for
            filing of loss and damage claims, and time limits for filing any
            action at law for dis-allowance of claims, shall be governed by the
            provisions contained in 49 U.S.C. &sect;&sect;14705 &amp; 14706.
            <br />
            <br />
          </li>
          <li>
            <strong>Undercharge and Overcharge Claims</strong> <br />
            Except as otherwise expressly provided for herein, all claims for
            overcharge, undercharge and duplicate payment shall be processed as
            provided in 49 C.F.R. Part 378. The time for filing of initial
            claims for alleged undercharges, overcharges or duplicate payments
            under the terms of this CA shall be one hundred and eighty (180)
            days from the date of receipt of the original invoice containing
            such disputed charges. Failure to file a claim challenging initial
            charges within said one hundred and eighty (180) day period shall
            forever bar any action at law for recovery of the same. Any action
            at law by either party to collect alleged undercharges or
            overcharges under the terms of this CA shall be commenced not later
            than eighteen (18) months after delivery of the shipment. Expiration
            of said eighteen (18) month term shall be a complete and absolute
            defense against any such <br />
            claim, regardless or any extenuating or mitigating circumstances or
            excuses of any nature whatsoever.
            <br />
            <br />
          </li>
          <li>
            <strong>Indemnity</strong>
            <br />
            <br />
            <strong>10.1 Terms</strong> <br />
            CARRIER shall defend, indemnify and hold ARCHERHUB and its Customers
            (&ldquo;Indemnitee(s)&rdquo;) harmless from all fines, costs,
            penalties, liabilities and claims of every kind, including
            attorneys&rsquo; fees, costs of suit, settlements, judgments, and
            all other expenses to which ARCHERHUB or its Customers may be
            subjected on account of bodily injury to persons (including injury
            resulting in death) and loss of or damage to any property whatsoever
            (including cargo), violation of Law, or any other claim arising out
            of or in connection with the transportation of property under this
            CA by CARRIER or the personnel, contractors, subcontractors or any
            other agent of CARRIER. CARRIER&rsquo;s obligation to defend,
            indemnify and hold ARCHERHUB and its Customers harmless under this
            provision shall not in any manner be subject to any limitation on
            the amount or types of <br />
            damages, compensation or benefits payable by CARRIER or the
            contractors, subcontractors or other agents of CARRIER under
            applicable worker&rsquo;s compensation acts, disability benefit acts
            or other employee benefit acts, and CARRIER hereby specifically
            waives, and shall cause its independent contractors, subcontractors,
            and owner operators to waive any immunity any of them may have under
            such acts. ARCHERHUB shall defend, indemnify and hold harmless from
            all fines, costs, penalties, liabilities and claims of every kind
            including attorney&rsquo;s fees, <br />
            costs of suit, settlements, judgments, and all other expenses to
            which CARRIER may be subjected on account of bodily injury to
            persons (including injury resulting in death) and loss of or damage
            to any property whatsoever (including cargo), violation of law, or
            any other claim caused by the sole gross negligence or intentional
            wrongful acts or omissions of ARCHERHUB.
            <br />
            <br />
            <strong>10.2 Notice of Claims</strong> <br />
            ARCHERHUB shall give CARRIER notice of any claim or suit coming
            within the purview of the foregoing indemnity. CARRIER will assume
            the defense of any claim, demand or action against an Indemnitee and
            will, upon the request of the Indemnitee, allow the Indemnitee to
            participate in the defense thereof, such participation to be at the
            expense of the Indemnitee. Termination of this CA shall not affect
            the continuing obligations of CARRIER hereunder with respect to
            those acts, breaches, failures or omissions falling within the
            purview of the foregoing indemnity and which shall have occurred
            prior to such termination.
            <br />
            <br />
          </li>
          <li>
            <strong>Compliance with Laws</strong> <br />
            With respect to the transportation services provided herein, CARRIER
            shall comply, and cause its independent contractors, subcontractors,
            or owner operators to comply, with all applicable Federal, state and
            local laws, rules, regulations and ordinances, including, but not
            limited to all rules and regulations promulgated by the DOT and all
            other federal and state agencies and departments having jurisdiction
            over the transportation services to be performed. CARRIER shall
            defend, indemnify, and hold ARCHERHUB and its Customers harmless
            from and against any and all fines, penalties, judgments,
            liabilities, expenses and costs of any nature whatsoever arising or
            resulting from CARRIER&rsquo;s or its independent contractors,
            subcontractors, or owner operators&rsquo; failure to comply with all
            such laws, rules, regulations and ordinances.
            <br />
            <br />
          </li>
          <li>
            <strong>Force Majeure</strong> <br />
            Non-performance caused by acts of God or government, fire, riots,
            wars, strikes, labor disturbances, major snow storms, natural
            catastrophes, or other circumstances beyond the control of the
            Parties shall be excused so long as the hindrance to performance
            exists. CARRIER will notify ARCHERHUB immediately upon incurring any
            Force Majeure condition which prevents their performance under this
            CA.
            <br />
            <br />
          </li>
          <li>
            <strong>Notices</strong> <br />
            Any notice required by this CA shall be in writing and sent via
            certified mail, return receipt requested or by overnight courier to:{" "}
            <br />
            Archerhub, <br />
            1860 Blake St. Suite 620
            <br />
            Denver, CO 80202 <br />
            Attn: Legal Department
            <br />
            <br />
          </li>
          <li>
            <strong>Term/Termination</strong> <br />
            The term of this CA shall begin on the last date it is executed by
            either of the parties or the acceptance of CARRIER of any Tender
            from ARCHERHUB pursuant to this CA, whichever occurs first, and
            shall continue for a term of one year. The CA shall be renewed
            automatically from year to year thereafter. Provided, however, that
            either party may terminate this CA at any time during the initial
            term or any renewal term by giving the other party no less than
            thirty (30) days prior written notice. Termination of this CA shall
            also constitute termination of any Amendment hereto. The obligations
            reflected in paragraphs 4.2, 6, 8, 9, 10 and 13 shall survive the
            termination of this CA.
            <br />
            <br />
          </li>
          <li>
            <strong>Choice of Law and Forum/Savings Clause</strong> <br />
            <strong>&nbsp;</strong>This CA and any addenda and any conflict
            arising thereunder shall be subject to all applicable United States
            federal law and the laws of the State of Colorado, without regard to
            its conflict of law rules. The parties agree that for all claims
            personal jurisdiction and venue shall be in the State of <br />
            Colorado and CARRIER agrees to waive any and all objections thereto.
            If any part of this CA is deemed to be in violation of any law, such
            part shall be severed form this CA and the remaining provisions of
            the CA shall continue in full force and effect.
            <br />
            <br />
          </li>
          <li>
            <strong>Confidentiality</strong> <br />
            Carrier acknowledges that any and all information emanating from
            ARCHERHUB&#39;s or its Customer&#39;s business, in any form,
            including any compilations or otherwise nonpublic information is
            &ldquo;Confidential Information&quot; and Carrier shall not, during
            or after the term of the Agreement for five years, permit the
            duplication, use or disclosure of any such Confidential Information
            to any person (other than its own employees, agent or representative
            who must have such information for the performance of its
            obligations hereunder), unless such duplication, use or disclosure
            is specifically authorized by ARCHERHUB or its Customer. Carrier
            shall be responsible for any unauthorized disclosure made by any of
            its employees, agents, or representatives and shall take reasonable
            precautions to prevent such disclosures. Carrier acknowledges that
            the unauthorized disclosure or use of ARCHERHUB&#39;s or its
            Customer&#39;s Confidential Information would cause irreparable
            harm, the degree of, which may be difficult to ascertain.
            Accordingly, Carrier agrees that ARCHERHUB or its Customer will as a
            matter of right be entitled to obtain an immediate injunction,
            without posting of bond, enjoining any actual or proposed breach of
            this Agreement, as well as the right to pursue any and all other
            rights and remedies available at law or in equity for such a breach.
            <br />
            <br />
          </li>
        </ol>

        <p style={{ textAlign: "center" }}>
          <u>
            <strong>
              ATTACHMENT A TO CA ARCHERHUB CARGO CLAIMS AND ACCIDENT REPORTING
              PROCEDURES
            </strong>
          </u>
        </p>
        <br />
        <p>
          <strong>I. ACCIDENTS</strong> <br />
          All accidents/events, meeting the criteria outlined below, should be
          reporting to Archerhub&rsquo;s safety/claims department
          (1-303-323-4933). The phones are staffed 24 hours per day 365 days per
          year. All reportable accidents/events should be reported to the
          safety/claims office within 2 hours of the occurrence. The only
          exception to that requirement is an accident/event that results in a
          fatality and that should be reported immediately. <br />
          1. Any accident/event that could potentially result in cargo damage{" "}
          <br />
          2. Any accident/event that results in a fatality <br />
          3. Any accident/event that results in a serious injury (defined as any
          life altering or life threatening injury). Examples of these would
          include but not necessarily be limited to amputations, loss of bodily
          functions, loss of eyesight, etc. <br />
          4. Any accident/event expected to result in losses totaling more than
          $50,000 <br />
          5. Any accident/event involving Archerhub equipment (i.e. trailers or
          containers) <br />
          6. Any accident/event involving Archerhub employees.
          <br />
          <br />
          <strong>II. CARGO CLAIMS</strong> <br />
          <strong>Loss and Damage</strong>
          <br />
          Cargo claims, whether they are caused by shortage or damage, are an
          expense that can be reduced with your assistance. Call ARCHERHUB Cargo
          Claims (OS&amp;D Dept.) from the shipping or receiving dock, or
          anywhere in transit, when you have cargo damage, overage, shortage, or
          other discrepancy. ARCHERHUB Cargo (OS&amp;D) can be reached at
          303-323-4933 24 hours per day, 7 days per week, 365 days per year.
          Each load has its own ARCHERHUB load number, which is to be shown on
          all paperwork. It is to be placed in the top right hand corner of the
          Bill of Lading, or <br />
          as close as possible to the top right hand corner.
          <br />
          <br />
          <strong>When to Call</strong> <br />
          1. Anytime there is an exception at the time of delivery; overage,
          shortage, damage, or simply seal exception.
          <br />
          2. If a shipper refuses to seal the load or place the seal number on
          the paperwork. <br />
          3. Anytime during transit, while doing your regular equipment checks,
          if you notice a seal has been broken, or compromised. <br />
          4. When picking up a unit at a rail yard, call in if you see a
          different seal number than what is on the bills, or if you see any
          trailer damage. PLEASE MAKE SURE that you note these exceptions on the
          rail outgate report, as well as reporting to the rail police. <br />
          5. When picking up a unit at a drop yard, call in if seal is different
          than what is on the BOL, or if seal is missing.
          <br />
          6. Anytime there is any kind of mishap during transit that could
          affect cargo, such as an accident or incident. If the cargo to be
          loaded is in poor condition or the count is wrong, tell the shipper.
          If a disagreement occurs, call ARCHERHUB Cargo/OS&amp;D before loading
          or signing for the load.
          <br />
          <br />
          <strong>Steps to Take to Avoid Cargo Claims</strong>
          <br />
          <br />
          <strong>1. Shipper Load and Driver Count (SLDC):&nbsp;</strong>Check
          condition and count. It will take longer to ensure an accurate count,
          but take your time! Have the shipper seal the load and note the
          correct seal number on all copies of Bill of Lading. <br />
          <strong>2. Shipper Load and Count (SLC):&nbsp;</strong>Be sure bills
          of lading show correct seal number and Shipper&rsquo;s responsibility
          by the statement &ldquo;Shipper&rsquo;s Load and Count, or SLC&rdquo;.{" "}
          <br />
          <strong>3. Driver Loads and Counts (DLC):</strong> Driver responsible
          for the cargo count, as well as ensuring proper loading, blocking, and
          bracing of the load for transit. <br />
          <strong>4. Driver Unload (DU):</strong>
          Always break the seal in presence of receiver and have him/her sign
          &ldquo;Seal #<span>______&nbsp;</span>
          intact&rdquo; on BOL. <br />
          <strong>5. Receiver Unload/Driver Assist (RUDA):&nbsp;</strong>If any
          damages, shortages, or overages are noted, call us at 303-323-4933
          immediately before leaving the receiver.
          <br />
          <strong>6. Receiver Unload (RU):&nbsp;</strong>When dropping a
          trailer, have the receiver, or receiver&rsquo;s agent, sign
          &ldquo;Seal#______ intact&rdquo; on the BOL. If a Live unload, and any
          damages, shortages, or overages occur, call OSD immediately before
          leaving the receiver. If a load shifts during transit and has to be
          corrected, the CARRIER in possession of the load at the time the load
          shift is discovered is responsible for any fees to get the load
          corrected. The Claims OS&amp;D group can help the CARRIER find a
          vendor to help with this, but the CARRIER will be billed, not
          ARCHERHUB. The same will be true for instances of correcting
          overweight trailers, or transloading freight because of equipment
          problems.
          <br />
          <br />
          <strong>III. CARGO SECURITY</strong>
          <br />
          CARRIER shall comply with generally accepted industry standards
          regarding cargo security and CARRIER&rsquo;s insurance policy
          requirements.
        </p>

        <p>
          <br />
        </p>

        <p style={{ textAlign: "center" }}>
          <strong>
            <u>
              ATTACHMENT B TO CA EXTRA REQUIREMENTS FOR HAZARDOUS MATERIALS
              SHIPMENTS
            </u>
          </strong>
        </p>

        <p style={{ textAlign: "left" }}>
          <br />
          For any shipment arranged by ARCHERHUB to be transported by CARRIER
          involving the transportation of hazardous materials, the parties agree
          the following provisions shall apply, in addition to provisions in the
          CA to which this Appendix is attached:
          <br />
          1. CARRIER represents and warrants that it holds all Federal and/or
          state permits and registrations necessary to transport hazardous
          materials. CARRIER shall provide ARCHERHUB copes of all appropriate
          documents upon ARCHERHUB&rsquo;s request. <br />
          2. CARRIER shall immediately notify ARCHERHUB of (a) any relocation or
          suspension of its permits and registrations described in section 1,
          above, and of (b) any change in its USDOT safety rating.
          <br />
          3. CARRIER also represents and warrants all of its drivers shall be,
          at the time they transport any hazardous material shipment, (a)
          properly trained under Federal and state laws, including, as example,
          49 CFR &sect;&sect;172.700 and 177.800, and (b) have the proper
          endorsements on their Commercial Driver&rsquo;s License to transport
          such shipments. <br />
          4. CARRIER shall comply with all Federal, state, and local laws
          regarding the transportation of hazardous materials, including, as
          example, 49 CFR Parts 172 and 397. <br />
          5. If CARRIER is tendered a shipment of hazardous materials it must
          maintain, at a minimum $1 million ($US) liability coverage under 49
          CFR &sect;387.9. CARRIER shall ensure that it procures and maintains,
          at its sole expense, public liability and property damage insurance
          from a reputable and financially responsible insurance company
          insuring CARRIER for at least $1 million ($US) per occurrence. Such
          insurance shall name CARRIER and ARCHERHUB as insureds for any and all
          liabilities for personal injuries (including death) and property
          damage, including environmental damage due to the release of a
          hazardous substance and/or hazardous material, arising out of or in
          any way related to CARRIER&rsquo;s transportation operation. As
          evidence of this coverage, CARRIER shall provide ARCHERHUB a copy of
          the proper hazardous materials endorsement evidencing such coverage.
        </p>
      </div>

	  <p className="text-base my-5">If you accept all the terms of the Carrier Agreement, click the <b>I agree with ALL Carrier Agreement terms</b> checkbox , state your name and title click next to continue. You must accept the carrier agreement to continue.</p>

	  <Checkbox  label="I agree with ALL Carrier Agreement terms" value="Carrier agree with CA terms" onCheckboxChange={handleCAUserAgree} isSelected={store.carrier.AgreedWithCATerms}/>
    <form >
    <TextField
          id="name"
          type="text"
          label="Name"
          name="name"
          value={store.carrier.AcceptedBy}
          error={errors.name}
          registerFn={register}
        />    
 <TextField
          id="title"
          type="text"
          label="Title"
          name="title" 
          value={store.carrier.AcceptedByTitle}
          error={errors.title}
          registerFn={register}
        />   
      
      <div className="mb-6"></div>
      <div className="flex items-center justify-between">
        <Back action={back} />
        <Next action={handleSubmit(onSubmit)} isEnabled={!store.carrier.AgreedWithCATerms
        }/>
      </div>  </form> 
    </>
  )
}
