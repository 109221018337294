import React, { useContext, useState } from "react"
import { navigate } from "gatsby"
import Back from "./buttons/Back"
import { Context } from "./store"
import moment from "moment"
import { renderEmail } from "react-html-email"
import CarrierService from "../../services/carrier-service"
import CompanyService from "../../services/company-service"
import LogService from "../../services/log-service"
import LoadService from "../../services/load-service"
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import AttachmentService from "../../services/attachment-service"
import Progress from "./upload/Progress"
import EmailBody from "../shared/email"
const HtmlToReactParser = require('html-to-react').Parser;
export default function SumbitPage({ currentStep, back, next }) {
  const { dispatch, store } = useContext(Context)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [submit, setSubmit] = useState(false)
  const [disabled, setDisabled] = useState(false)
  if (currentStep !== 10) {
    return null
  }
  if (!store.Progress)
    store.Progress = 0;
  const carrierService = new CarrierService()
  const attachmentService = new AttachmentService()
  const companyService = new CompanyService()
  const loadService = new LoadService()
  const logService = new LogService()
  let documentTypes = [
    "Select One",
    "Cargo Insurance",
    "Carrier Authority",
    "Liability Insurance",
    "Other",
    "W9"
  ]
  //console.log(process.env.uri, store.dispatcher.Email);


  const getPreview = () => {

    let preview = `
   <h2 className="text-black text-2xl">Company</h2>
   <hr className="mt-1" />
   <div className="my-2 flex">
   ${store.carrier.MCNum ? `
   <div className="mr-5">
   <span className="font-bold">MC Number</span><br>
   <span className="text-gray-700">${store.carrier.MCNum}</span>
   </div>` : ''}
   
   ${store.carrier.USDOTNum ? `
   <div className="ml-5">
   <span className="font-bold">USDOT Number</span><br>
   <span className="text-gray-700">${store.carrier.USDOTNum}</span>
   </div>` : ''}
   </div>
   <div className="my-2">
     <h4 className="font-bold">Name</h4>
     <h5 className="text-gray-700">${store.carrier.Name}</h5>
   </div>
   <div className="my-2">
     <h4 className="font-bold">Address</h4>
     <h5 className="text-gray-700">${store.fullAddress}</h5>
   </div>
   <div className="flex flex-wrap ">
     <div className="w-full md:w-1/3 my-2">
       <h4 className="font-bold">Phone</h4>
       <h5 className="text-gray-700">${store.carrier.Address.Phone[0]}</h5>
     </div>

     ${store.carrier.Fax !== "" ? (
        `<div className="w-full md:w-1/3 my-2">
         <h4 className="font-bold">Fax</h4>
         <h5 className="text-gray-700">${store.carrier.Address.Fax}</h5>
       </div>`
      ) : ''}

     <div className="w-full md:w-1/3 my-2">
       <h4 className="font-bold">Email</h4>
       <h5 className="text-gray-700">${store.carrier.Address.Email[0]}</h5>
     </div>
   </div>
  
   ${store.carrier.Address.Contacts && store.carrier.Address.Contacts.length !== 0 ?
        `<div>
 <h2 className="text-black text-2xl mt-8">Contacts</h2>
   <hr />
       <div className="flex flex-wrap">
         <div className="w-full md:w-1/3">
           <div className="mt-2">
             <h4 className="block font-bold">Name</h4>
           </div>
         </div>
         <div className="w-full md:w-1/3">
           <div className="mt-2">
             <h4 className="block font-bold">Phone</h4>
           </div>
         </div>
         <div className="w-full md:w-1/3">
           <div className="mt-2">
             <h4 className="block font-bold">Email</h4>
           </div>
         </div>
       </div>
     </div>`
        : ''}
   ${store.carrier.Address.Contacts && store.carrier.Address.Contacts.length !== 0
        ? store.carrier.Address.Contacts.map((contact, contactindex) => {
          return (
            `<div className="flex flex-wrap">
           <div className="w-full md:w-1/3">
             <h5 className="block text-gray-700">${contact.Name}</h5>
           </div>
           <div className="w-full md:w-1/3">
             <h5 className="block text-gray-700">${contact.Phone}</h5>
           </div>
           <div className="w-full md:w-1/3">
             <h5 className="block text-gray-700">${contact.Email}</h5>
           </div>
         </div>`
          )
        }).join('')
        : ''}

     <h2 className="text-black text-2xl mt-8">Insurance Info</h2>
   <hr className="my-1" />
   <div className="flex block flex-wrap">
     ${store.carrier.InsuranceInfo && store.carrier.InsuranceInfo.length > 0 ?
        store.carrier.InsuranceInfo.map((obj, i) => {
          return (`
        <div key={i} className="mb-2">
          <strong>${obj.Type}</strong>:
          <span > ${obj.PolicyNumber}</span>
          <span className="ml-2"><strong>Issued: </strong> ${obj.IssueDate ? obj.IssueDate : 'NA'}</span>
          <span className="ml-2"><strong>Exp: </strong> ${obj.ExpirationDate}</span>
        </div>
        `)
        }).join('') : ''}
  </div>

   <h2 className="text-black text-2xl mt-8">Capabilities</h2>
   <hr className="my-1" />

   <div className="flex block flex-wrap">
     ${store.carrier.FleetDetails
        ? Object.keys(store.carrier.FleetDetails).map((serviceType, index) => {
          if (store.carrier.FleetDetails[serviceType] === true) {
            return (
              `<div className="w-full sm:w-1/2 md:w-1/3 text-gray-700" >
               ${serviceType}
             </div>`
            )
          }
        }).join('')
        : ''}
   </div>

   <h2 className="text-black text-2xl mt-8">Preferred Lanes</h2>
   <hr className="my-1" />

   <div className="flex flex-wrap">
     <div className="w-full md:w-1/3">
       <div className="mt-2">
         <h4 className="block font-bold">Origin</h4>
       </div>
     </div>
     <div className="w-full md:w-1/3">
       <div className="mt-2">
         <h4 className="block font-bold">Destination</h4>
       </div>
     </div>
   </div>

   ${store.carrier.NewCarrierLanes && store.carrier.NewCarrierLanes.length !== 0
        ?
        store.carrier.NewCarrierLanes.map((lane, laneindex) => {
          return (`<div className="flex flex-wrap">
      <div className="w-full md:w-1/3">
      ${lane.PointALaneAddress ? `<h5 className="block text-gray-700">${lane.PointALaneAddress.City} , ${lane.PointALaneAddress.State}</h5>`:''}
      </div>
      <div className="w-full md:w-1/3">
      ${lane.PointBLaneAddress ? `<h5 className="block text-gray-700">${lane.PointBLaneAddress.City} , ${lane.PointBLaneAddress.State}</h5>`:''}
      </div>
    </div>`
          )
        }).join('')

        : ''}

   <h2 className="text-black text-2xl mt-8">Payment</h2>
   <hr className="my-1" />

   <div className="flex flex-wrap">
   <div className="w-full md:w-1/3 my-2">
     <h4 className="font-bold">Method</h4>
     <h5 className="text-gray-700">${store.carrier.PaymentMethod}</h5>
   </div>
 ${store.carrier.BankingInfo.RequestQP && store.carrier.PaymentMethod === "Direct Deposit" ?
        `<div className="my-2">
     <h4 className="font-bold">Quickpay</h4>
     <h5 className="text-gray-700">${store.carrier.BankingInfo.RequestQP}</h5>
   </div>`: ''}
   </div>
   ${(store.carrier.PaymentMethod && store.carrier.PaymentMethod === "E-Check") ||
        store.carrier.PaymentMethod === "Factoring Agent" ? (
          `<div className="my-2">
           <h4 className="font-bold">Name</h4>
           <h5 className="text-gray-700">${store.carrier.PaymentMethod === "Factoring Agent" ? store.carrier.FactoringCompany.Name : store.carrier.CheckInfo.Name}</h5>
         </div>

         <div className="my-2 ">
           <h4 className="font-bold">Address</h4>
           <h5 className="text-gray-700">${store.fullPaymentAddress}</h5>
         </div>
         <div className="flex flex-wrap">
           <div className="w-full md:w-1/3 my-2 ">
             <h4 className="font-bold">Phone</h4>
             <h5 className="text-gray-700">${store.carrier.PaymentMethod === "Factoring Agent" ? store.carrier.FactoringCompany.PhysicalAddress.Phone[0] : store.carrier.CheckInfo.Address.Phone[0]}</h5>
           </div>

           <div className="w-full md:w-1/3 my-2">
             <h4 className="font-bold">Fax</h4>
             <h5 className="text-gray-700">${store.carrier.PaymentMethod === "Factoring Agent" ? store.carrier.FactoringCompany.PhysicalAddress.Fax : store.carrier.CheckInfo.Address.Fax}</h5>
           </div>
           <div className="w-full md:w-1/3 my-2">
             <h4 className="font-bold">Email</h4>
             <h5 className="text-gray-700">${store.carrier.PaymentMethod === "Factoring Agent" ? store.carrier.FactoringCompany.PhysicalAddress.Email[0] : store.carrier.CheckInfo.Address.Email[0]}</h5>
           </div>
         </div>`

        ) : store.carrier.PaymentMethod === "Direct Deposit" ? (
          `<div className="flex flex-wrap">
           <div className="w-full md:w-1/3 my-2 ">
             <h4 className="font-bold">Cash Advance</h4>
             <h5 className="text-gray-700">
               ${store.carrier.BankingInfo.CashAdvance}
             </h5>
           </div>

           <div className="my-2">
             <h4 className="font-bold">Account Type</h4>
             <h5 className="text-gray-700">
               ${store.carrier.BankingInfo.AccountType}
             </h5>
           </div>
         </div>
         <div className="flex flex-wrap">
           <div className="w-full md:w-1/3 my-2 ">
             <h4 className="font-bold">Bank Name</h4>
             <h5 className="text-gray-700">${store.carrier.BankingInfo.BankName}</h5>
           </div>

           <div className="my-2">
             <h4 className="font-bold">Address</h4>
             <h5 className="text-gray-700">
               ${store.fullBankAddress}
             </h5>
           </div>
         </div>

         <div className="flex flex-wrap">
           <div className="w-full md:w-1/3 my-2 ">
             <h4 className="font-bold">Transit/Routing Number</h4>
             <h5 className="text-gray-700">
               ${store.carrier.BankingInfo.RoutingNumber}
             </h5>
           </div>

           <div className="my-2">
             <h4 className="font-bold">Account Number</h4>
             <h5 className="text-gray-700">
              ${store.carrier.BankingInfo.AccountNumber}
             </h5>
           </div> </div>
       `
        ) : ''}


   <h2 className="text-black text-2xl mt-8">1099 Information</h2>
   <hr />
   <div className="my-2">
     <h4 className="font-bold">Company Type</h4>
     <h5 className="text-gray-700">${store.carrier.Ten99.CompanyType}</h5>
   </div>
   <div className="flex flex-wrap">
     <div className="w-full md:w-1/2 my-2">
       <h4 className="font-bold">Taxpayer Identification Number (TIN)</h4>
       <h5 className="text-gray-700">${store.carrier.FedTaxId}</h5>
     </div>
   </div>


   <div className="my-2">
     <h4 className="font-bold">Address</h4>
     <h5 className="text-gray-700">${store.fullTaxAddress}</h5>
   </div>
   <h2 className="text-black text-2xl mt-8">Accepted By</h2>
   <hr />
   <div className="flex flex-wrap ">
   <div className="w-full md:w-1/3 my-2">
     <h4 className="font-bold">Name</h4>
     <h5 className="text-gray-700">${store.carrier.AcceptedBy}</h5>
   </div>

   ${store.carrier.Fax !== "" ? (
        `<div className="w-full md:w-1/3 my-2">
       <h4 className="font-bold">Title</h4>
       <h5 className="text-gray-700">${store.carrier.AcceptedByTitle}</h5>
     </div>`
      ) : ''}

   <div className="w-full md:w-1/3 my-2">
     <h4 className="font-bold">Date</h4>
     <h5 className="text-gray-700">${moment(new Date()).format("MMMM Do YYYY")}</h5>
   </div>
 </div>
   ${store.carrier.Attachments.length > 0 ?
        `<h2 className="text-black text-2xl mt-8">Documents</h2>
   <hr className="my-1" />

   <div className="flex flex-wrap">
     <div className="w-full md:w-1/3">
       <div className="mt-2">
         <h4 className="block font-bold">Document Type</h4>
       </div>
     </div>
     <div className="w-full md:w-2/3">
       <div className="mt-2">
         <h4 className="block font-bold">File Name</h4>
       </div>
     </div>
   </div>`: ''}

   ${store.carrier.Attachments.length > 0
        ? store.carrier.Attachments.map((file, docindex) => {
          return (
              `<div className="flex flex-wrap">
           <div className="w-full md:w-1/3">
             <h5 className="block text-gray-700">${file.AttachmentType}</h5>
           </div>
           <div className="w-full md:w-2/3">
             <h5 className="block text-gray-700">${file.AttachmentPath}</h5>
           </div>
         </div>`
          )
        }).join('')
        : ''} `;
    let parser = new HtmlToReactParser();
    return parser.parse(preview);
  }
  const onSubmit = async e => {
    console.log("submit");
    setSubmit(true);
    setDisabled(true);
    const config = {
      headers: {
        Authorization:
          "Basic " + carrierService.getToken(),
      }, onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //console.log(percentCompleted)
        dispatch({ type: "setProgress", data: percentCompleted })
      }
    }
    e.preventDefault()
    const formData = new FormData()
    //console.log(store.carrier);
    let attachFile = [];
    let attachments = [];
    let updatedLogs = [];
    let tempFile = [];

    let table = [];
    // let documentTypes = [
    //   "Select One",
    //   "Insurance Certificate",
    //   "Carrier Authority",
    //   "Other",
    //   "W9",
    // ]

    if (store.fileTemp && store.fileTemp.length > 0) {
      // store.carrier.Attachments.filter(function (item) {
      //   if (documentTypes.includes(item.AttachmentType) || item.IsDeleted == true) {
      //     table.push(item);
      //   }
      // });
      // console.log();

      store.fileTemp.forEach(async (file, index) => {
        formData.append("file", file);
        console.log(file, index);
        tempFile[index] = store.carrier.Attachments[index];
        tempFile[index].ParentType = "Carrier"
        tempFile[index].ParentId = store.carrier.id;
        const description = `Document ${store.carrier.Attachments[index].AttachmentType} was added.`;
        const log = {}
        log.Description = description;
        log.Time = moment(new Date());
        log.User = store.dispatcher ? store.dispatcher.Name : null;
        log.LogType = "Attachments"
        log.ParentId = store.carrier.id;
        updatedLogs.push(log);

      });

      console.log(attachFile);
      dispatch({ type: "setState", data: store });
      console.log(attachFile, updatedLogs);
      const blob = await carrierService.uploadDoc(
        formData, config
      );

      if (blob) {
        console.log(tempFile)
        attachments = await attachmentService
          .CreateDocuments(tempFile);
        if (attachments) {
          console.log(blob, attachments);
        }
        setTimeout(() => {
          dispatch({ type: "setProgress", data: 0 });
        }, 5000)

      }
      attachFile = tempFile;
    }
    else {
      attachFile = store.Attachments.concat(tempFile);
    }


    if(store.carrier.PaymentMethod === "Factoring Agent"){
      const companies = await companyService.FactoringAutocomplete(store.carrier.FactoringCompany.Name)
      if(companies.length > 0){
        store.carrier.FactoringCompanyID = companies[0].id
      }else{
        var comp = Object.assign({},store.carrier.FactoringCompany)
        comp.CompanyType = ["Factoring Company"]
        const factCompany = await companyService.Create({Company:comp,Attachments:[]})
        store.carrier.FactoringCompanyID = factCompany.id
      }
    }

    let carrier = null
    let attachmentsUpdate = null
    if (store.carrierExist) {
      if (
        store.carrier.Status === "Expired Insurance" &&
        store.carrier.InsuranceInformation &&
        new Date(
          store.carrier.InsuranceInformation.CargoExpirationDate
        ).getTime() > new Date().getTime()
      ) {
        store.carrier.Status = 'Active';
      } else {
        store.carrier.Status = 'Packet Completed';
      }

      const description = 'Carrier updated from Archerhub website.';
      const log = {}
      log.Description = description;
      log.Time = moment(new Date());
      log.User = store.dispatcher ? store.dispatcher.Name : null;
      log.ParentId = store.carrier.id;
      log.LogType = "Carrier";
      updatedLogs.push(log);
      store.carrier.Attachments = attachFile;
      if (store.AttachmentRemoved) {
        store.AttachmentRemoved.forEach(async (file, index) => {
          if (file.IsDeleted === true) {
            const description = `Document ${file.AttachmentType} was removed.`;
            const log = {}
            log.Description = description;
            log.Time = moment(new Date());
            log.User = store.dispatcher ? store.dispatcher.Name : null;
            log.LogType = "Attachments"
            log.ParentId = store.carrier.id;
            updatedLogs.push(log);
          }

        });
        attachmentsUpdate = await attachmentService
          .UpdateDocuments(store.AttachmentRemoved);
        if (attachmentsUpdate) {
          console.log(attachmentsUpdate);
        }
      }
      dispatch({ type: "setUpdate" })
      const updateCarrier = {
        Carrier: store.carrier,
        Logs: updatedLogs,
        Attachments: store.carrier.Attachments
      };
      dispatch({ type: "setState", data: store });

      carrier = await carrierService.update(updateCarrier);
      if (carrier) {
        console.log(carrier);

      }

    }
    else {
      const description = 'Carrier created from Archerhub website.';
      const log = {}
      log.Description = description;
      log.Time = moment(new Date());
      log.User = store.dispatcher ? store.dispatcher.Name : null;

      store.carrier.Logs = [log];
      store.carrier.CreatedOnBehalfOf = store.dispatcher ? store.dispatcher.Id : null;
      store.carrier.CreatedViaWebsite = true;
      store.carrier.CarrierType = "Carrier";
      dispatch({ type: "setState", data: store });
      var createObj = {
        Carrier: store.carrier, Attachments: store.carrier.Attachments.map(attachment => {
          attachment.ParentType = "Carrier"
          return attachment
        })
      }
      carrier = await carrierService.create(createObj);
    }
    if (carrier) {
      console.log("carrier");


      if (store.dispatcher) {

        const emailHTML = renderEmail(
          <EmailBody title="Carrier Sign up">
            Click here to <a href={process.env.NODE_ENV === "production" ? `https://tms.archerhub.com/#/carriers/${carrier.id}/edit` : `https://devtms.archerhub.com/#/carriers/${carrier.id}/edit`}>view carrier</a> in TMS
        </EmailBody>)
        const emailObj = {
          SendTo: [store.dispatcher.Email],
          Subject: `Carrier ${store.carrier.Name} signup complete`,
          Body: emailHTML,
          SendFromEmail: "tms@archeratlantic.com"
        }
        await loadService.SendEmail(emailObj);

      }

      let htmlInput = ` <style>
          .my-2 {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
      }
          .flex {
          display: -webkit-box;
          display: flex; 
      }

          .flex-wrap {
          flex-wrap: wrap;

      }
          .md\:w-1\/3 {
          width: 33.333333%;

      }
          .md\:w-1\/2 {
          width: 50%;
      
      }
      .block {
        display: block !important; 
      }
      .w-full {
        width: 100%;
      }
          .mt-6 {
          margin-top: 1.5rem;
      }
          .mt-8 {
          margin-top: 2rem;
      }
      </style>`;
      let htmlToReactParser = new HtmlToReactParser();
      let reactElement = htmlToReactParser.parse(htmlInput);
      const emailHTML1 = renderEmail(
        <EmailBody title="Carrier SignUp">
          {reactElement}
          <p>Thank you for completing the archerhub online carrier packet. A member from our sales team will contact you shortly. If you believe this email reached you in error, please contact opsden@archerhub.com </p>
          {getPreview()}
        </EmailBody>)
      const emailObj1 = {
        SendTo: process.env.NODE_ENV === "production" ? [carrier.Address.Email[0]] :
          ["developers@avantmark.com "],
        Subject: `Carrier ${store.carrier.Name} signup complete`,
        Attachments:[carrier],
        Body: emailHTML1,
        SendFromEmail: "tms@archeratlantic.com"
      }
      await loadService.SendEmail(emailObj1);
      setSuccess("Carrier " + carrier.Name + " has been registered. You will be contacted by one of our agents.");
      setSubmit(false);
      setTimeout(() => {
        navigate("/")
        setSuccess(null);
      }, 10000)


    }
  }
  return (
    < >

      <h2 className="text-gray-700 text-xl mb-4">Preview</h2>
      {getPreview()}

      {store.carrier.Attachments.length > 0 ? <Progress percentage={store.Progress} /> : null}
      {success ? (
        <div
          className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-teal-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="font-bold">Success Carrier Saved </p>
              <p className="text-sm">{success}</p>
            </div>
          </div>
        </div>
      ) : null}
      {error ? (
        <div
          className="bg-red-100 border-t-4 border-red-500 rounded-b text-black-900 px-4 py-3 shadow-md"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-red-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="font-bold">Error</p>
              <p className="text-sm">{error}</p>
            </div>
          </div>
        </div>
      ) : null}
      <div className="mt-6" />

      {<div className="flex justify-between">
        <Back action={back} />
        <button id="submitMessage"
          disabled={disabled}
          className="bg-black hover:bg-blue-400 text-white font-bold py-2 px-4 mb-4 rounded focus:outline-none focus:shadow-outline inline-flex items-center"
          type="button" onClick={onSubmit} >Submit  {submit ? (
            <span className="inline-block ml-1">
              <Loader
                type="Bars"
                color="#3182ce"
                height={20}
                width={30}
              />
            </span>
          ) : null}</button>
      </div>}
    </>
  )
}
