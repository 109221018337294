import axios from "axios"
import ls from "local-storage"
import Base64 from "Base64"

class CompanyService{
    token = "";
    userid = "";
  
    constructor() {
      const usr = ls.get("wauid")
      try {
        this.token = JSON.parse(Base64.atob(usr)).Auth
        this.userid = JSON.parse(Base64.atob(usr)).Id
      } catch (err) {
        console.log(err);
      }
      //   console.log(this.token);
    }
  
    getToken() {
      return this.token;
    }

    Create = async(updateObj) =>{
        const res = await axios.post(process.env.apiurl + "companies/CreateCompany2", updateObj, {
            headers: { Authorization: "Basic " + this.token },
        })
        return res.data
    }
    FactoringAutocomplete  = async (text) =>{
        const search = { SuggestionText: text };
        const res = await axios.post(process.env.apiurl + 'search/SuggestFactoringCompany', search, {
            headers: { Authorization: "Basic " + this.token },
        })
        let results = [];
        if (res.data && res.data.Data) {
        results = res.data.Data.map((document) => document.Document);
        }
        return results;
    }
    GetCompany = async(id)=>{
      const res = await axios.get(process.env.apiurl + 'companies/GetCompany/' + id, {
        headers: { Authorization: "Basic " + this.token },
      })
      return (res.data ? res.data : null)
    }
}
export default CompanyService