import React, { useContext } from "react"
import TextField from "./inputfields/TextField"
import Checkbox from "./inputfields/Checkbox"
import AddressTextField from "./AddressField/AddressTextField"
import Next from "./buttons/Next"
import Back from "./buttons/Back"
import { Context } from "./store"
import { useForm } from "react-hook-form"
import * as yup from "yup"


const schema = yup.object().shape({
  accountType: yup.string().required("Account Type is required"),
  bankName: yup.string().required("Bank Name is required"),
  address: yup.string().required("Address is required"),
  transitNumber: yup
    .number()
    .typeError("Numbers only")
    .required("Tansit Number is required"),
  accountNumber: yup
    .number()
    .typeError("Numbers only")
    .required("Account number is required"),
  //email: yup.string().required("Email is required"),
})

export default function DirectDeposit({ back, next }) {
  const { register, handleSubmit, errors} = useForm({
    validationSchema: schema,
    mode: "onChange",
  })

  const { store, dispatch } = useContext(Context)
  
  const onSubmit = data => {
    dispatch({ type: "addPaymentDetails", data })
    next()
  }
  const handleSelectedAddress = address => {
    dispatch({
      type: "updateBankAddress",
      data: address,
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="font-bold mb-3">Are you requesting Quick Pay?</p>
        <label className="block text-gray-900">
          <input
            className="mr-2 leading-tight"
            type="radio"
            name="requestQP"
            value="yes"
            defaultChecked={store.carrier.RequireQuickPay=== true}
            ref={register}
          />
          <span className="text-sm">Yes</span>
          <p className="text-xs font-normal ml-5">
            Please fax invoices and all required documents to 1-720-708-2456 or
            email to qp@archerhub.com. A fixed 3.5% processing fee for Quick Pay
            will be deducted for each final settlement.
          </p>
        </label>
        <label className="block text-gray-900">
          <input
            className="mr-2 leading-tight"
            type="radio"
            name="requestQP"
            value="no"
            defaultChecked={store.carrier.RequireQuickPay === false}
            ref={register}
          />
          <span className="text-sm">No</span>
        </label>
        <div className="mt-6"></div>
        <div className="font-bold">
          <Checkbox
            label="I DO NOT want to receive cash advances"
            name="cashAdvance"
            value="no"
            isSelected={store.carrier.BankingInfo.CashAdvance === "no"}
            registerFn={register}
          />
        </div>

        <p className="text-xs font-normal ml-5">
          Carriers will be approved for cash advances after three(3)
          satisfactory performances of freight hauling. Each Cash Advance will
          incur a fee of $20, with a maximum advance of 40% of the total freight
          charge, and a maximum of $1000 per day.
        </p>

        <div className="mt-6"></div>

        <span className="inline text-gray-900 font-bold mr-5">
          Account type*
        </span>

        <label className="inline text-gray-900 mr-5">
          <input
            className="mr-2 leading-tight"
            type="radio"
            name="accountType"
            defaultChecked={store.carrier.BankingInfo.AccountType === "Checking"}
            value="Checking"
            ref={register}
          />
          <span className="text-sm">Checking</span>
        </label>

        <label className="inline text-gray-900">
          <input
            className="mr-2 leading-tight"
            type="radio"
            name="accountType"
            defaultChecked={
              store.carrier.BankingInfo.AccountType === "Savings"
            }
            value="Savings"
            ref={register}
          />
          <span className="text-sm">Savings</span>
        </label>
        {errors.accountType ? (
          <span className="text-red-500 text-xs italic">
            {errors.accountType.message}
          </span>
        ) : (
          " "
        )}
        <div className="mt-6"></div>

        <TextField
          label="Bank Name*"
          name="bankName"
          value={store.carrier.BankingInfo.BankName}
          registerFn={register}
          error={errors.bankName}
        />


<AddressTextField   
id="address"       
          name="address"
          label="Address*"
          addressType="address"
          placeholder=""
          value={store.fullBankAddress}
          registerFn={register}
          onSelectResult={handleSelectedAddress}
          error={errors.address}
        />

        <div className="flex flex-wrap -mx-3">
          <div className="w-full md:w-1/2 px-3 md:mb-0">
            <TextField
              type="number"
              label="Transit/Routing Number*"
              name="transitNumber"
              value={store.carrier.BankingInfo.RoutingNumber}
              registerFn={register}
              error={errors.transitNumber}

            />
          </div>
          <div className="w-full md:w-1/2 px-3 md:mb-0">
            <TextField
              type="number"
              label="Account Number*"
              name="accountNumber"
              value={store.carrier.BankingInfo.AccountNumber}
              registerFn={register}
              error={errors.accountNumber}
            />
          </div>
        </div>
        <TextField
          type="email"
          label="Email"
          name="email"
          value={store.carrier.BankingInfo.Address.Email[0]}
          registerFn={register}
          error={errors.email}
        />
        <div className="mt-6"></div>

        <p className="text-xs font-normal">
          Designated amounts will usually post to your account within 48 hours
          of transmission depending on your bank’s posting procedures.
        </p>
        <p className="text-xs font-normal">
          Archerhub is not responsible for overdrafts incurred before funds are
          deposited.
        </p>
        <p className="text-xs font-normal">
          Changes to direct deposit accounts may not necessarily take effect on
          your next settlement.
        </p>
        <div className="mt-6"></div>
        <div className="flex items-center justify-between">
          <Back action={back} />        
          <Next />
        </div>
      </form>
    </>
  )
}
