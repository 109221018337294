import React, { Fragment } from "react"
//import Progress from "./Progress"
import Select from "../inputfields/Select"
import Table from "../Table/Table"
//let files = []
const FileUpload = ({ state, handleTable, handleSelect, handleFile ,type}) => {
  // const [file, setFile] = useState("")
  // const [filename, setFilename] = useState("Files")
  // const [uploadedFile, setUploadedFile] = useState({})
  // const [message, setMessage] = useState("")
  // const [uploadPercentage, setUploadPercentage] = useState(0)
  let tableData=[];
  let documentTypes = [
    "Select One",
    "Insurance Certificate",
    "Carrier Authority",
    "Other",
  ]
if(type==="Tax"){
    documentTypes = [
    "Select One",
    "W9",
  ]

}
  /*const onChange = e => {
    if (e.target.files[0]) {
      setFile(e.target.files[0])
      setFilename(e.target.files[0].name)
      files.push({
        "Document Type": state.DocumentType,
        "File Name": e.target.files[0].name,
      })
      console.log("onChange ", e.target.files, files)
    }
    e.target.value = ""
  }*/

  const onSubmit = async e => {
    e.preventDefault()
    const formData = new FormData()
    state.fileTemp.forEach(file => {
      formData.append("file", file)
    })

    //console.log("file")

  }

  return (
    <Fragment>
      {/* {message ? (
        <div
          className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-teal-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="font-bold">Uploaded</p>
              <p className="text-sm"></p>
            </div>
          </div>
        </div>
      ) : null} */}
      <form name="Document" onSubmit={onSubmit}>
        {type==="Insurance"?<Select
          label="Document Type"
          id="docs"
          name="DocumentType"
          selected={state.insurance}
          onChange={handleSelect}
          options={documentTypes}
        />:null}

        <input
          disabled={state.DocumentType == null }
          className="h-12 border rounded w-full  text-gray-700 mb-4 mt-4 focus:outline-none focus:shadow-outline text-2xl pr-10 bg-gray-200"
          type="file"
          id="customFile"
          label="Choose File (image/pdf)"
          accept="application/pdf,image/jpg,image/png,image/jpeg,image/jpeg 2000,text/plain,application/octet-stream"
          multiple
          placeholder="choose W9 file"
          title={ ""}
          onChange={handleFile}
        />

        {/* <Progress percentage={uploadPercentage} /> */}

        {state.carrier.Attachments && state.carrier.Attachments.length > 0 ? (
          <div>
            {/* <button
              disabled={message}
              className="bg-black hover:bg-blue-400 text-white font-bold py-2 px-4 mb-4 rounded focus:outline-none focus:shadow-outline inline-flex items-center"
              type="submit"
            >
              Upload
            </button> */}
          <div className="w-full mb-4 ">
         { 
           state.carrier.Attachments.filter(function(item) {
           if(documentTypes.includes(item.AttachmentType)||item.IsDeleted===true)
            tableData.push(item)
           // console.log(tableData);
  
})}
            {tableData &&tableData.length>0? <Table
                data={tableData}
                handleTable={handleTable}
              />:null}
            </div>
          </div>
        ) : (
          ""
        )}
      </form>

    </Fragment>
  )
}

export default FileUpload
