import React, { useContext ,useState } from "react"
import TextField from "./inputfields/TextField"
import Checkbox from "./inputfields/Checkbox"
import Next from "./buttons/Next"
import Back from "./buttons/Back"
import FileUpload from "./upload/fileUpload"
import AddressTextField from "./AddressField/AddressTextField"
import { Context } from "./store"
import { useForm } from "react-hook-form"
import * as yup from "yup"

const schema = yup.object().shape({
  companyType: yup.string().required("Company Type is required"),
  //tin: yup.string().required("Taxpayer Identification Number is required"),
  tin: yup
    .string()
    .required("Taxpayer Identification Number (TIN) is required")
    .test(
      "tin",
      "Taxpayer Identification Number (TIN) consists of 9 digits",
      function(value) {
        if (!!value) {
          const schema = yup.string().matches(/^[0-9]{9}$/)
          return schema.isValidSync(value)
        }
        return true
      }
    ),
  //ein: yup.string().required("Employer Identification Number(EIN) is required"),
  //ssn: yup.mixed().notRequired().length(9, "Social Security Number (SSN) consists of 9 digits"),
  //ssn: yup.string().matches(/^[0-9]{9}$/,'Social Security Number (SSN) consists of 9 digits'),
  // ssn: yup
  //   .string()
  //   .notRequired()
  //   .test("ssn", "Social Security Number (SSN) consists of 9 digits", function(
  //     value
  //   ) {
  //     if (!!value) {
  //       const schema = yup.string().matches(/^[0-9]{9}$/)
  //       return schema.isValidSync(value)
  //     }
  //     return true
  //   }),
  // ein: yup
  //   .string()
  //   .required("Employer Identification Number (EIN) is required")
  //   .test(
  //     "ein",
  //     "Employer Identification Number (EIN) consists of 9 digits",
  //     function(value) {
  //       if (!!value) {
  //         const schema = yup.string().matches(/^[0-9]{9}$/)
  //         return schema.isValidSync(value)
  //       }
  //       return true
  //     }
  //   ),
  acceptTaxInfoTerms: yup
    .string()
    .matches(/(yes)/, "Please accept the above terms to continue"),
    address: yup.string().required("Address is required")
})

export default function TaxInfo({ currentStep, back, next }) {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
    mode: "onChange",
  })

const [isAuthority, setIsAuthority] = useState(false)
const [message, setMessage] = useState("")
var extList = "";
let documentTypes = [
  "W9"
]
  const { store, dispatch } = useContext(Context)
  if(!store.carrier.Ten99){
    store.carrier.Ten99= {
      CompanyType:"",
      Ein: false,
      SSN:false,
      Address:{},
      AcceptTaxInfoTerms:false
    } 
  }
  if (currentStep !== 8) {

    return null
  }

  const handleChange = e => {
    dispatch({ type: "setTin", data: e.target.value })
    dispatch({ type: "setDocumentType", data:"W9" })
  }



  const handleSelectedAddress = address => {
     dispatch({
       type: "setTaxInfoAddress",
       data: address,
     })
   }
 
   const handleSelect = e => {
    dispatch({ type: "setDocumentType", data: e.target.value })
}


const getExtension=(filename)=> {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}

const isImage=(file)=> {
  
 for(var name in file){
   var ext = getExtension(file[name].name);
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'png': 
    case 'pdf':
    case 'jfif':    
      //etc
      return true;
  }

extList+=" "+ext.toLowerCase();

  return false;} 
}
  const handleFile = e => {
    extList="";
    if(e.target.files.length==0){
     console.log(e.target.files);
      return;
  }
   var error= isImage(e.target.files);
   
   console.log(error,extList);
   if(!error==true){
    setIsAuthority(true);
    setMessage("An Invalid file type"+extList+" was found please try again with a (pdf , png or jpg) file");
    setTimeout(() => {
      setIsAuthority(false); 
       setMessage("");
   }, 10000); 
   return;
 }
    
    let docs = Array.from(e.target.files)
    e.target.value = ""
    docs.map(
      (file, index) => {
        var newName  = file.name.split(".")[0]+"_"+new Date().getTime()+'.'+file.name.split(".")[1]
        var newFormData = new FormData()
        newFormData.append('file', file, newName)
        var newFile = newFormData.get('file')
        store.doc
          ? dispatch({
              type: "setFiles",
              data: {
                AttachmentPath: newFile.name,
                AttachmentType: store.doc,
                Date: new Date().toLocaleString(),
              },
            })
          : dispatch({
              type: "setFiles",
              data: {
                AttachmentPath: newFile.name,
                AttachmentType: store.DocumentType,
                Date: new Date().toLocaleString(),
              },
            })

        dispatch({ type: "fileTemp", data: newFile })
      }
    )
  }
  const handleDocTable = index => {

    dispatch({ type: "removeFileFromList", data: {data:index,type:"W9" }})
}
  const onSubmit = data => {
    let check=[];
    store.carrier.Attachments.filter((item)=> {
      if(documentTypes.includes(item.AttachmentType)||item.IsDeleted==true){
       check.push(item.AttachmentType);
      }
});  
 if(check.length===0){
  setIsAuthority(true);
  setMessage("A W9 document is required");
  setTimeout(() => {
    setIsAuthority(false); 
     setMessage("");
 }, 5000); }else{
    dispatch({ type: "addTaxInfo", data })
    next()
  }
  }

  //console.log(store)

  return (
    <>
    <form >
      <h2 className="text-gray-700 text-xl mb-4">1099 Information</h2>
      <h2 className="text-gray-700 font-bold text-xl mb-4">
        {store.carrier.Name} is a...
      </h2>

      <div className="flex flex-wrap mb-2">
        <div className="w-full md:w-1/2 lg:w-1/4">
          <label className="inline text-gray-900 mr-5">
            <input
              className="mr-2 leading-tight"
              type="radio"
              name="companyType"
              value="Corporation"
              ref={register}
              defaultChecked={store.carrier.Ten99.CompanyType === "Corporation"}
            />
            <span className="text-sm">Corporation</span>
          </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
          <label className="inline text-gray-900 mr-5">
            <input
              className="mr-2 leading-tight"
              type="radio"
              name="companyType"
              value="LLC"
              ref={register}
              defaultChecked={store.carrier.Ten99.CompanyType === "LLC"}
            />
            <span className="text-sm">LLC</span>
          </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
          <label className="inline text-gray-900 mr-5">
            <input
              className="mr-2 leading-tight"
              type="radio"
              name="companyType"
              value="Individual"
              ref={register}
              defaultChecked={store.carrier.Ten99.CompanyType === "Individual"}
            />
            <span className="text-sm">Individual</span>
          </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
          <label className="inline text-gray-900 mr-5">
            <input
              className="mr-2 leading-tight"
              type="radio"
              name="companyType"
              value="Other"
              ref={register}
              defaultChecked={store.carrier.Ten99.CompanyType === "Other"}
            />
            <span className="text-sm">Other</span>
          </label>
        </div>
        {errors.companyType ? (
          <span className="text-red-500 text-xs italic">
            {errors.companyType.message}
          </span>
        ) : (
          " "
        )}
      </div>

      <div className="mb-6"></div>

      <div className="relative">
        <TextField
          type="number"
          label="Taxpayer Identification Number (TIN)*"
          name="tin"
          max="9"
          value={store.carrier.FedTaxId}
          registerFn={register}
          error={errors.tin}
        />
      </div>

      {/* <div className="flex flex-wrap -mx-3">
        <div className="w-full md:w-1/2 px-3 md:mb-0">
          <TextField
            type="number"
            label="Employer Identification Number (EIN)*"
            name="ein"
            value={store.carrier.Ten99.Ein}
            registerFn={register}
            error={errors.ein}
          />
        </div>
        <div className="w-full md:w-1/2 px-3 md:mb-0">
          <TextField
            type="number"
            label="Social Security Number"
            name="ssn"
            value={store.carrier.Ten99.SSN}
            registerFn={register}
            error={errors.ssn}
          />
        </div>
      </div> */}
      <div className="flex flex-wrap mb-2">
        <div className="w-full md:w-1/2 lg:w-1/4">
          <label className="inline text-gray-900 mr-5">
            <input
              className="mr-2 leading-tight"
              type="radio"
              name="ssnein"
              value="EIN"
              onChange={handleChange}
              ref={register}
              defaultChecked={store.carrier.Ten99.Ein === true}
            />
            <span className="text-sm">EIN</span>
          </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
          <label className="inline text-gray-900 mr-5">
            <input
              className="mr-2 leading-tight"
              type="radio"
              name="ssnein"
              value="SSN"
              onChange={handleChange}
              ref={register}
              defaultChecked={store.carrier.Ten99.SSN === true}
            />
            <span className="text-sm">SSN</span>
          </label>
        </div>

        {errors.companyType ? (
          <span className="text-red-500 text-xs italic">
            {errors.companyType.message}
          </span>
        ) : (
          " "
        )}
      </div>
    

      <AddressTextField
        name="address"
        label="Address*"
        addressType="address"
        placeholder=""
        value={store.fullTaxAddress}
        registerFn={register}
        onSelectResult={handleSelectedAddress}
        error={errors.address}
      />  <div className="mt-6" />

    
     
      <div className="mb-6" />

      <Checkbox
        label="Under penalties of perjury, I certify that.."
        name="acceptTaxInfoTerms"
        value="yes"
        isSelected={store.carrier.Ten99.AcceptTaxInfoTerms=== true}
        registerFn={register}
      />

      <div className="ml-5">
        <p className="text-xs font-normal">
          1) The number shown on the form is my correct EIN and/or SSN
        </p>
        <p className="text-xs font-normal">
          2) I am not subject to backup withholding
        </p>
        <p className="text-xs font-normal">
          3) I am a US. Person (including US resident alien)
        </p>

        {errors.acceptTaxInfoTerms ? (
          <span className="text-red-500 text-xs italic">
            {errors.acceptTaxInfoTerms.message}
          </span>
        ) : (
          " "
        )}
      </div>
  
    </form>
    <div className="mb-4" />
    <p><b>Upload W9 file</b></p><FileUpload
     state={store}
     handleTable={handleDocTable}
     handleSelect={handleSelect}
     handleFile={handleFile}
     type="Tax"
    />
      {isAuthority?<span className="text-red-500 text-xs italic">{message}</span>:null}
      <div className="mb-6" />
      <div className="flex items-center justify-between">
        <Back action={back} />
        <Next action={handleSubmit(onSubmit)} />
      </div>
      
      </>
  )
}
