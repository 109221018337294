import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FormContainer from "../components/carrier_onboarding/FormContainer"
import queryString from "query-string"
import { isIE } from 'react-device-detect';

class CarrierSignup extends Component {
  state = {
    mcnum: null,
    source: null
  }
  constructor(props) {
    super(props)
    let query = this.props.location.search
      ? queryString.parse(this.props.location.search)
      : {}
    if (query.mcnum) {
      this.state = { mcnum: query.mcnum, source: query.source ? query.source : null }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      let query = this.props.location.search
        ? queryString.parse(this.props.location.search)
        : {}
      if (query.mcnum) {
        this.setState({ mcnum: query.mcnum, source: query.source ? query.source : null })
      }
    }
  }
  render() {
    if (isIE) { return (
      <Layout>
      <SEO title="Signup" />
      <div className="sm:mt-16 lg:mt-32 lg:mb-10">
      <div> IE is not supported. Download Chrome/Opera/Firefox </div>
      </div>
    </Layout>
    ) }
    return (
      <Layout>
        <SEO title="Signup" />
        <div className="sm:mt-16 lg:mt-32 lg:mb-10">
          <FormContainer mcnum={this.state.mcnum} source={this.state.source} />
        </div>
      </Layout>
    )
  }
}
export default CarrierSignup