import React from "react"

export default function Back({ action }) {
  return (
    <>
      <button
        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
        onClick={action}
      >
        <span>Back</span>
      </button>
    </>
  )
}
