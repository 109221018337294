import React, { useContext } from "react"
import Checkbox from "./inputfields/Checkbox"
import Next from "./buttons/Next"
import Back from "./buttons/Back"
//import TextField from "./inputfields/TextField"
//import Select from "react-select"
import { Context } from "./store"
/*
const options = [
  { label: "Alabama", value: "Alabama" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "Alaska", value: "Alaska" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "Wyoming", value: "Wyoming" },
]
*/
export default function Capabilities({ currentStep, back, next }) {
  //const [selectedAlcoholStates, setAlcoholStates] = useState(null)

  const { store, dispatch } = useContext(Context)
  if(!store.carrier.FleetDetails)
  {
   store.carrier.FleetDetails= {
      "FleetSize": null,
      "Tractors": 0,
      "Trailers": 0,
      "Van": false,
      "Refrigerated": false,
      "Intermodal": false,
      "CurtainSide": false,
      "Flatbed": false,
      "StepDeck": false,
      "DoubleDrop": false,
      "HeavyHaulTrailer": false,
      "Tankers": false,
      "Pneumatics": false,
      "Bulk": false,
      "TeamDrivers": false,
      "LTL": false,
      "Warehousing": false,
      "Hazmat": false,
      "TankEndorse": false,
      "Conestoga": false,
      "DumpTrailer": false,
      "Lowboy": false,
      "PowerOnly": false,
      "StraightBoxTruck": false,
      "DryVan": false,
      "LiftGate": false,
      "Specialty": null,
      "Notes": null,
      "Truckload": false,
      "SpecializedOSD": false,
      "SmallPackage": false,
      "Air": false,
      "AutoHauler": false,
      "Escorts": false,
      "StraightTruck": false,
      "CurtainSides": false,
      "PlateTrailers": false,
      "Other": false,
      "Commodities": false,
      "ETrack": false,
      "ODHauls": false,
      "CanhaulAlcohol": false,
      "Canhaulhazardous": false,
      "CARBcompliant": false,
      "TWICcompliant": false
    }
  }
  if (currentStep !== 5) {
    return null
  }

  const handleServiceTypeChange = e => {
    var find = ' ';
    var re = new RegExp(find, 'g');
    var str = e.target.value.replace(re, '');
    dispatch({ type: "setServiceType", data:{'name':str,'value':e.target.checked} })
  }
/*
  const handleCapabilitiesChange = e => {
    dispatch({ type: "setCapabilities", data: e.target.name })
  }*/
  /*
  const handleAlcoholPermitStates = selectedValues => {
    const states = selectedValues.map(selectedStates => selectedStates.value)
    setAlcoholStates(selectedValues)
    dispatch({ type: "setAlcoholPermitStates", data: states })
  }
  
  const selectAlcoholPermitStates = () => {
    return (
      <div className="ml-5 max-w-md">
        <span className="text-sm">
          Have active permits that authorizes you to haul alcohol?
        </span>
        <Select
          options={options}
          isMulti={true}
          isSearchable={true}
          placeholder="Select the States"
          onChange={handleAlcoholPermitStates}
          value={selectedAlcoholStates}
        />
      </div>
    )
  }
*/
  return (
    <>
      <h2 className="text-gray-700 text-xl mb-8">
        Carrier Capabilities and type of Services
      </h2>
  <div className="flex flex-wrap mb-2 ">
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox 
        className="text-black-700 red"
            label={"Dry Van"}
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.DryVan===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label={"Flatbed"}
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Flatbed===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label={"Refrigerated"}
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Refrigerated===true}
          />
        </div>
        </div>
        <div className="mt-6" />
      <div className="flex flex-wrap mb-2">
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Air"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Air===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
           <Checkbox
            label="Auto Hauler"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.AutoHauler===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Bulk"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Bulk===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4"> 
        <Checkbox
            label="Commodities"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Commodities===true}
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
        <div className="w-full md:w-1/2 lg:w-1/4">
          <Checkbox
            label="Conestoga"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Conestoga===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Curtain Side"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.CurtainSide===true}
          />
        </div>
      
        <div className="w-full md:w-1/2 lg:w-1/4">
          <Checkbox
            label="Double Drop"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.DoubleDrop===true}
          />
        </div>  <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Dump Trailer"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.DumpTrailer===true}
          /> 
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
      
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="E Track"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.ETrack===true}
          />
          
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Escorts"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Escorts===true}
          />
        </div>       
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Hazmat"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Hazmat===true}
          /> 
        </div><div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Heavy Haul Trailer"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.HeavyHaulTrailer===true}
          /> 
        </div>
      </div>
      
       <div className="flex flex-wrap mb-2">
        
        <div className="w-full md:w-1/2 lg:w-1/4">
         <Checkbox
            label="Intermodal"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Intermodal===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="LTL"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.LTL===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Lowboy"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Lowboy===true}
          />
        </div><div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="OD Hauls"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.ODHauls===true}
          />
        </div>
      </div>
       <div className="flex flex-wrap mb-2">
       
        
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Other"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Other===true}
          />
          
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Plate Trailers"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.PlateTrailers===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
          <Checkbox
            label="Pneumatics"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Pneumatics===true}
          />
        </div><div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Power Only"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.PowerOnly===true}
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
        
        
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Small Package"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.SmallPackage===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Specialized OSD"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.SpecializedOSD===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Step Deck"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.StepDeck===true}
          />
        </div><div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Straight Box Truck"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.StraightBoxTruck===true}
          />
        </div>
      </div>
      <div className="flex flex-wrap mb-2">
       
        
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Straight Truck"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.StraightTruck===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Tank Endorse"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.TankEndorse===true}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Tankers"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Tankers===true}
          />
        </div><div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Team Drivers"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.TeamDrivers===true}
          />
        </div>
      </div>

      <div className="flex flex-wrap mb-2">
       

        
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Truckload"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Truckload===true}
          />
        </div>
        
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Van"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Van===true}
          />
        
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
        <Checkbox
            label="Warehousing"
            onCheckboxChange={handleServiceTypeChange}
            isSelected={store.carrier.FleetDetails.Warehousing===true}
          />
        </div>
      </div>


      

      <div className="mb-6"></div>

      {/* <Checkbox
        label="Have active permits that authorizes you to haul alcohol?"
        name="Can haul Alcohol"
        value="Can haul Alcohol"
        onCheckboxChange={handleServiceTypeChange}
        isSelected={store.carrier.FleetDetails.CanhaulAlcohol===true}
      />

      <div className="mb-6"></div>
      <Checkbox
        label="Have active permits that authorixes you to haul hazardous?"
        name="Can haul hazardous"
        value="Can haul hazardous"
        onCheckboxChange={handleServiceTypeChange}
        isSelected={store.carrier.FleetDetails.Canhaulhazardous===true}
      />
      <div className="mb-6"></div>

      <Checkbox
        label="Have trucks that are CARB compliant?"
        name="CARB compliant"
        value="CARB compliant"
        onCheckboxChange={handleServiceTypeChange}
        isSelected={store.carrier.FleetDetails.CARBcompliant===true}
      />
      <div className="ml-5 max-w-md"></div>
      <div className="mb-6"></div>

      <Checkbox
        label="Have trucks that are TWIC compliant?"
        name="TWIC compliant"
        value="TWIC compliant"
        onCheckboxChange={handleServiceTypeChange}
        isSelected={store.carrier.FleetDetails.TWICcompliant===true}
      /> */}
      <div className="ml-5 max-w-md"></div>
      <div className="mb-6"></div>

      <div className="mb-6"></div>
      <div className="flex items-center justify-between">
        <Back action={back} />
        <Next action={next} />
      </div>
    </>
  )
}
