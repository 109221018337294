  
import React from 'react';
import PropTypes from 'prop-types';

const Progress = ({ percentage }) => {
  return (
    
    <div className='progress'>
      <div className="shadow w-full bg-grey-light mt-4 mb-4">
    <div className="bg-blue-700 text-xs leading-none py-1 text-center text-white"  role='progressbar'   style={{ width: `${percentage}%`}}> {percentage}%</div>
  </div>
    
    </div>
  );
};

Progress.propTypes = {
  percentage: PropTypes.number.isRequired
};

export default Progress;