import React, { useContext,useState } from "react"
import TextField from "./inputfields/TextField"
import Submit from "./buttons/Submit"
import Table from "./Table/Table"
import Next from "./buttons/Next"
import Back from "./buttons/Back"
import { useForm } from "react-hook-form"
import { Context } from "./store"
import InputMask from "react-input-mask"
import * as yup from "yup"


const schema = yup.object().shape({
  name: yup.string().required("Name is required"), 
  phone: yup.string().required("Phone is required"),
  email: yup.string().required("Email is required").matches(new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),"Invalid Format "
)})

export default function AdditionalContacts({ currentStep, back, next }) {
  const { register, handleSubmit, errors, formState } = useForm({
    validationSchema: schema,
    mode: "onChange",
  })
  const { store, dispatch } = useContext(Context)
  const [phone, setPhone] = useState("") 
  if (currentStep !== 4) {
    return null
  }
  
  const resetForm = () => {  
  document.getElementById("name").value="";
  document.getElementById("email").value="";
  setPhone("");
  document.getElementById("phone").value="";

  }
  const onSubmit = data => {  
console.log("submit")
    dispatch({ type: "addAdditionalContacts", data })
    resetForm();

  }

  const handleTable = index => { 
    dispatch({ type: "removeContact", data: index });
    //resetForm();
     
  
  
  }  
  const handle=() => { 

console.log(formState)
let name=document.getElementById("name").value;
let email= document.getElementById("email").value;
let phone= document.getElementById("phone").value;
console.log(phone,phone.length)
if(name&&email&&phone&&phone.length===14&&email.match(new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"))){
  let data= {"name":name ,"email": email,"phone": phone}
  dispatch({ type: "addAdditionalContacts", data })
}
  resetForm();
    next();
  
  }

  return (
    <>
      <h2 className="text-gray-700 text-xl mb-4">Additional Contacts</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          id="name"
          type="text"
          label="Name"
          name="name"
          error={errors.name}
          registerFn={register}
        />       
             <label
          className="block text-gray-900 text-sm font-bold mt-2 mb-2"
          htmlFor={"phone"}
        >
          {"Phone*"}
        </label>
          <InputMask
            mask="(999) 999-9999"
            type="tel"
            id="phone"
            title="Phone*"
            min="10"
            placeholder="(123) 456-7890"
            className="h-12 border rounded w-full px-3 text-gray-700 focus:outline-none focus:shadow-outline text-2xl pr-10 bg-gray-200"
           value={phone}
           onChange={(e) => {setPhone(e.target.value)}}
            error={errors.phone}
            inputRef={register}
            name="phone"
          ></InputMask>

        <TextField
          id="email"
          type="email"
          label="Email"
          name="email"
          error={errors.email}
          registerFn={register}
        />

        <div className="mb-6"></div>

        <div className="flex items-center justify-between">
          <Submit title="Add More" isEnabled={!formState.isValid} />
        </div><div className="mb-6"></div>

     
      {store.carrier.Address.Contacts && store.carrier.Address.Contacts.length > 0 ? (
        <Table data={store.carrier.Address.Contacts} handleTable={handleTable} />
      ) : null}

      
      </form>
<div className="mb-6"></div>

      <div className="flex items-center justify-between">
        <Back action={back} />
        <Next action={handle}/>
      </div>
      
    </>
  )
}
