import { Contact } from './contact';
import { Coordinates } from './coordinates';

class Address {
  GoogleId = '';
  Name = '';
  Street = '';
  ApartmentNumber = '';
  City = '';
  Zip = '';
  State = '';
  Country = '';
  Phone = [];
  Email = [];
  Fax = '';
  PhoneExtension = '';
  // Do not remove
  FormattedAddress = '';
  Contacts = [];
  Coordinates;
  CoordinatesFound = false;

  constructor(address) {
    if (address) {
      Object.assign(this, address);
    }
  }
}

export default Address