import React, { useContext } from "react"
import { Context } from "./store"
import TextField from "./inputfields/TextField"
import AddressTextField from "./AddressField/AddressTextField"
import Next from "./buttons/Next"
import Back from "./buttons/Back"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"

//import GooglePlacesAutocomplete from "react-google-places-autocomplete"
// If you want to use the provided css
//import "react-google-places-autocomplete/dist/assets/index.css"

const schema = yup.object().shape({ 
   name: yup.string().required("Company name is required"),
  address: yup.string().required("Address is required"),
  phone: yup.string().required("Phone is required"),
  email: yup.string().required("Email is required"),
})

export default function VerifyAddress({ currentStep, back, next }) {
  const { store, dispatch } = useContext(Context)
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema,
    mode: "onChange",
  })
 
  if (currentStep !== 2) {
    return null
  }

  const onSubmit = data => {
    dispatch({
      type: "verifyAddress",
      data,
    })
    next()
  }
  const company = () => {
    dispatch({ type: "setCarrierType", data: "" })
    back()
  }
  const handleSelectedAddress = address => {
    dispatch({
      type: "updateStreetCityStateZip",
      data: address,
    })
  }
  
  const onTel = (event,type) => {
    console.log(event.target.value,type)
    dispatch({
     type: "verifyPhone",
     data: {"value": event.target.value,"type":type},
   })
}
  return (
    <div>
      <h2 className="text-gray-700 text-xl mb-4">
        Verify Address and Contact details
      </h2>
      <h3 className="font-bold">{store.carrier.Name}</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
     {store.carrierType==="Owner Operator"?<TextField 
        type="text"
        label="Company Name*"
        name="name"
        value={store.carrier.Name||""}
        error={errors.name}
        registerFn={register}
      /> :<TextField 
          type="hidden"
          name="name"
          value={store.carrier.Name||""}
          error={errors.name}
          registerFn={register}
        />}
        <AddressTextField
          addressType="address"
          label="Address*"
          name="address"
          error={errors.address}
          value={store.fullAddress}
          onSelectResult={handleSelectedAddress}
          registerFn={register}
        />

        <div className="flex flex-wrap -mx-3">
          <div className="w-full md:w-1/2 px-3 md:mb-0">
          <label
          className="block text-gray-900 text-sm font-bold mt-2 mb-2"
          htmlFor={"phone"}
        >
          {"Phone*"}
        </label>
          <InputMask
            mask="(999) 999-9999"
            type="tel"
            id="Phone"
            title="Phone*"
            placeholder="(123) 456-7890"
            className="h-12 border rounded w-full px-3 text-gray-700 focus:outline-none focus:shadow-outline text-2xl pr-10 bg-gray-200"
            value={store.carrier.Address.Phone[0]}
            onChange={(e) => onTel(e,"Phone")}
            error={errors.phone}
            inputRef={register}
            name="phone"
          ></InputMask>
         
          </div>
          <div className="w-full md:w-1/2 px-3 md:mb-0">
          <label
          className="block text-gray-900 text-sm font-bold mt-2 mb-2"
          htmlFor={"fax"}
        >
          {"Fax"}
        </label>
        <InputMask
            mask="(999) 999-9999"
            type="tel"
            id="Fax"
            title="Fax"
            placeholder="(123) 456-7890"
            className="h-12 border rounded w-full px-3 text-gray-700 focus:outline-none focus:shadow-outline text-2xl pr-10 bg-gray-200"
            value={store.carrier.Address.Fax}
            onChange={(e) => onTel(e,"Fax")}
            name="fax"
            error={errors.fax}
            inputRef={register}
          ></InputMask>
          </div>
        </div>
        <TextField
          type="email"
          label="Email*"
          name="email"
          value={store.carrier.Address.Email[0]||""}
          error={errors.email}
          registerFn={register}
        />
        <div className="mb-6"></div>

        <div className="flex items-center justify-between">
          <Back action={company} />
          <Next />
        </div>
      </form>
    </div>
  )
}
