import Agent from './agent'

class Insurance {
    Agent = new Agent();
    PolicyNumber = '';
    IssueDate = '';
    ExpirationDate = '';
    Notes = '';
    Type = '';
  
    constructor(newInsurance) {
      if (newInsurance) {
        Object.assign(this, newInsurance);
      }
    }
  }
  export default Insurance