import React, { useContext, useState } from "react"
//import TextField from "./inputfields/TextField"
//import Select from "./inputfields/Select"
import Submit from "./buttons/Submit"
import Table from "./Table/Table"
import Next from "./buttons/Next"
import Back from "./buttons/Back"
//import { useForm } from "./useForm"
import { useForm } from "react-hook-form"
import { Context } from "./store"
import * as yup from "yup"

import AddressTextField from "./AddressField/AddressTextField"
const schema = yup.object().shape({
  origin: yup.string().required("Origin is required"),
  destination: yup.string().required("Destination is required"),
})

export default function PreferredLanes({ currentStep, back, next }) {
  const { register, handleSubmit, errors, reset, formState } = useForm({
    validationSchema: schema,
    mode: "onChange",
  })

  const { store, dispatch } = useContext(Context)

  var originLane = {}
  var destinationLane = {}


  if (currentStep !== 6) {
    return null
  }

  const addLane = data => {
    dispatch({ type: "setPrefferedLanes",data: {"Origin":(store.origin.originCity?store.origin.originCity+", ":"")+store.origin.originState,"Destination":(store.destination.destinationCity?store.destination.destinationCity+", ":"")+store.destination.destinationState}})
   /* dispatch({
      type: "setPrefferedLanes",
      data: { originLane: originLane, destinationLane: destinationLane },
    })*/
    //console.log(originLane)
    //console.log(destinationLane)

    reset()
  }
  const handle=() => { 

    console.log(formState)

    if( document.getElementById("origin").value&&document.getElementById("destination").value)
      dispatch({ type: "setPrefferedLanes",data: {"Origin":document.getElementById("origin").value.replace(", USA",""),"Destination":document.getElementById("destination").value.replace(", USA","")}})
      dispatch({ type: "setDocumentType", data:"W9" })
      reset();
        next();
      
      }
  const handleTable = index => {
    dispatch({ type: "removePrefferedLane", data: index })
  }

  const handleSelectedAddress = (address, type) => {
    console.log(address)
    if (type === "origin") {
      originLane = {
        originCity: address.locality,
        originState: address.administrative_area_level_1,
      }

      dispatch({ type: "setOriginLane", data: originLane })
      //setCityState({ ...cityState, originCity: address.locality, originState: address.administrative_area_level_1})
    } else if (type === "destination") {
      destinationLane = {
        destinationCity: address.locality,
        destinationState: address.administrative_area_level_1,
      }

      dispatch({ type: "setDestinationLane", data: destinationLane })
      //setCityState({ ...cityState, destinationCity: address.locality, destinationState: address.administrative_area_level_1})
    }

    //console.log(originLane)
    //console.log(destinationLane)
    //setCityState(originLane)

    //console.log(lane)

    //dispatch({ type: "setPrefferedLanes", data: {pointA:address.locality , state: address.administrative_area_level_1} })
    //console.log("SELECCTED_ADDR:", address,type)
  }

  //console.log(store)

  return (
    <>
      <form onSubmit={handleSubmit(addLane)}>
        <h2 className="text-gray-700 text-xl mb-8"> Preferred Lanes </h2>
        <h2> Origin </h2>
        <AddressTextField
          id="origin"
          name="origin"
          placeholder=""
          addressType="cityState"
          onSelectResult={handleSelectedAddress}
          registerFn={register}
          error={errors.origin}
        />
        <div className="mb-3"></div>
        <h2> Destination </h2>
        <AddressTextField 
          id="destination"
          name="destination"
          placeholder=""
          addressType="cityState"
          onSelectResult={handleSelectedAddress}
          registerFn={register}
          error={errors.destination}
        />

        <div className="mb-3"></div>
        <div className="flex items-center justify-between">
          <Submit title="Add Lane" isEnabled={!formState.isValid} />
        </div>

        <div className="mb-6" />

        {store.preferredLanes && store.preferredLanes.length > 0 ? (
          <Table data={store.preferredLanes} handleTable={handleTable} />
        ) : null}

        <div className="mb-6" />
      </form>
      <div className="flex items-center justify-between">
        <Back action={back} />
        <Next action={handle} />
      </div>
    </>
  )
}
