import axios from "axios"
import ls from "local-storage"
import Base64 from "Base64"

class AttachmentService {
 

    token = '';
    constructor(){
        const usr = ls.get('wauid');
        try{
            this.token = JSON.parse(Base64.atob(usr)).Auth;
        }catch(err){
  
        }
        
    } 
  CreateDocuments(attachments){
    return axios.post(
      `${process.env.apiurl}documents/LogDocuments`,
      attachments, {headers:{Authorization:'Basic ' + this.token }}
    );
  }

  UpdateDocument(attachment) {
    return axios.put(
      `${process.env.apiurl}documents/UpdateDocument`,
      attachment, {headers:{Authorization:'Basic ' + this.token }}
    );
  }

  UpdateDocuments(attachments) {
    return axios.put(
      `${process.env.apiurl}documents/UpdateDocuments`,
      attachments, {headers:{Authorization:'Basic ' + this.token }})
  }

  GetDocumentsByParentId(id){
    return axios.get(
      `${process.env.apiurl}documents/GetDocumentsByParent/${id}`,{headers:{Authorization:'Basic ' + this.token }}
    );
  }

  GetDocumentsByPath(attachmentPath){
    return axios.get(
      `${process.env.apiurl}documents/GetDocumentsByPath/${attachmentPath}`
    );
  }

  MergeFiles(files) {
    return axios.post(process.env.apiurl + 'loads/MergeFiles', files);
  }
}export default AttachmentService