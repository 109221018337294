import React, { useState, useContext } from "react"
import SearchField from "./inputfields/SearchField"
import CarrierService from "../../services/carrier-service"
import CompanyService from "../../services/company-service"
import AttachmentService from "../../services/attachment-service"
import LoadService from "../../services/load-service"
import { Context } from "./store"
import { useForm } from "react-hook-form"

export default function CarrierLookup({ mcnum, source, currentStep, action }) {
  const { register, handleSubmit } = useForm()
  const carrierService = new CarrierService();
  const companyService = new CompanyService();
  const loadService = new LoadService();
  const attachmentService = new AttachmentService();
  const { store, dispatch } = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)
  const [isWarning, setIsWarning] = useState(false)
  const [message, setMessage] = useState("")
  const [searchType, setSearchType] = useState("mcnum")
  const [placeholder, setPlaceholder] = useState("Enter your MC number")
  


const quicklookup = async()=>{
  var e = { "lookUpMcNumber": mcnum };
  //action();
  await onSubmit(e);
  if (source)
    dispatch({ type: "setCarrierSource", data: source })
}

const getDispatcher = async () => {
  await loadService.GetUserById(new URLSearchParams(window.location.search).get('did')).then(res => {
    if (res != null) {
      dispatch({ type: "setDispatcher", data: res })
      console.log(res);
    }
  },
    err => {
      //error
      //alert(err)
    })

}

const searchTypeChange = async(e)=>{
  console.log(e.target.value)
  setSearchType(e.target.value)
  if(e.target.value === 'mcnum'){
    setPlaceholder("Enter your MC number")
  }else{
    setPlaceholder("Enter your US DOT number")
  }
  //console.log(e.target.value)
}

const onSubmit = async e => {
  if (e.lookUpMcNumber !== undefined && e.lookUpMcNumber.trim() !== "") {
    if (!mcnum) { setIsLoading(true) }
    if (new URLSearchParams(window.location.search).get('did'))
      getDispatcher();
    let carrier = null;
    let attachments = null;
    let carrierList = [];
    if(searchType === 'mcnum'){
      carrierList = await carrierService.getCarriersByMC(e.lookUpMcNumber)
    }else if(searchType === 'dotnum'){
      carrierList = await carrierService.GetCarriersByDOTNum(e.lookUpMcNumber)
    }
    if (carrierList.length > 0) {
      dispatch({ type: "carrierExist", data: true })
      //setIsWarning(true); 
      //setMessage("A Carrier with the MCNumber " + e.lookUpMcNumber+" has already been setup with Archerhub, please complete the form to update your company information.");
      //   setTimeout(() => {
      //   setIsWarning(false);
      // }, 10000) 
      carrier = await carrierService.GetCarrierById(carrierList[0].id);
      if (carrier) {
        if (!mcnum) { setIsLoading(false) }

        //success
        console.log(carrier)
        //console.log(carrier, store);
        if(carrier.FactoringCompanyID){
          carrier.FactoringCompany = await companyService.GetCompany(carrier.FactoringCompanyID);
        }

        if (carrier != null) {

          dispatch({ type: "setCarrier", data: carrier })
          if (!mcnum) { setIsLoading(false) }
          dispatch({ type: "CarrierData", data: carrier })

        }
      } else {
        //error
        if (!mcnum) { setIsLoading(false) }

      }
    } else {
      carrier = await carrierService.getSaferCarrier(searchType === 'mcnum' ? 'mc'+e.lookUpMcNumber:e.lookUpMcNumber)
      console.log(carrier);
      if (carrier) {
        if (!mcnum) { setIsLoading(false) }
        //success
        //console.log("Front: ", res)

        if (carrier.Name && carrier.Name !== "") {

          console.log(carrier)
          dispatch({ type: "setCarrier", data: carrier })
          if (!mcnum) { setIsLoading(false) }
          dispatch({ type: "CarrierData", data: carrier })

        } else {
          if (!mcnum) { setIsWarning(true) }
          setMessage("A Carrier with the MCNumber " + e.lookUpMcNumber + " was not found please try again");
          setTimeout(() => {
            if (!mcnum) { setIsWarning(false); }

          }, 10000)
        }
      } else {
        //error
        if (!mcnum) { setIsLoading(false) }

      }
    }
    console.log();
    if (carrier.id !== "") {
      attachments = await attachmentService.GetDocumentsByParentId(carrier.id);
      if (attachments) {

        dispatch({ type: "setAttachments", data: attachments.data })

      }
    }
  }
}

const searchResultRow = () => {

  return (

    <div
      className="flex w-full w-full rounded overflow-hidden mt-5 mb-5 p-4 border-solid border border-gray-300 bg-gray-100 cursor-pointer"
      onClick={action}
      role="button"
      tabIndex={0}
      onKeyDown={action}
    >
      <div className="block">
        <h1 className="text-black font-bold">{store.carrier.Name}</h1>
        <h2 className="text-grey-darker text-base">
          {store.fullAddress}
        </h2>
      </div>
      <div className="flex flex-1 flex-grow w-full items-center justify-end">
        <svg
          className="fill-current w-6 h-6 ml-2"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 31.49 31.49"
          style={{ enableBackground: "new 0 0 31.49 31.49" }}
          xmlSpace="preserve"
        >
          <path
            d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
	C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
	c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"
          />
        </svg>
      </div>
    </div>
  )
}
if (currentStep !== 1) {
  return null
} else {
  if (mcnum && !store.carrier.Name) {
    quicklookup()
  }
}
return (

  <>
    <h2 className="text-gray-700 text-xl mb-4">Company Lookup</h2>
    <form onSubmit={handleSubmit(onSubmit)}>
      
    <div className="mb-3 font-semibold">
      <span className="mr-3"><input type="radio" name="searchtype" value="dotnum" onChange={searchTypeChange} checked={searchType === "dotnum"} /> USDOT Number</span>
      <span><input type="radio" name="searchtype" value="mcnum" onChange={searchTypeChange} checked={searchType === "mcnum"} /> MC Number</span>
    </div>
      <SearchField
        placeholder={placeholder}
        label="Search for your company"
        name="lookUpMcNumber"
        value={store.mcnumber}
        onSubmit={onSubmit}
        registerFn={register}
      />
    </form>

    {isLoading ? (
      <div>Loading...</div>
    ) : store.carrier.Name ? (
      searchResultRow()
    ) : null}
    {isWarning ? (
      <div
        className="bg-yellow-300 border-t-4 border-yellow-500 rounded-b text-black-900 px-4 py-3 shadow-md"
        role="alert"
      >
        <div className="flex">
          <div className="py-1">
            <svg
              className="fill-current h-6 w-6 text-yellow-600 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div >
            <p className="font-bold">Warning</p>
            <p className="text-sm">{message}</p>
          </div>
        </div>
      </div>
    ) : null}
  </>
)
}

