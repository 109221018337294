import React from "react"
import moment from "moment"
//import _ from 'lodash' 
const isEmptyObject = obj => {
  return (obj && (Object.keys(obj).length === 0));
}
export const initialState = {


  carrier: {
    InsuranceInformation: {
      GLPolicyNum: "",
      GLExpirationDate: "",
      CargoPolicyNum: "",
      CargoExpirationDate: "",
    },
    Attachments: [],
    Address: {
      Street: "",
      City: "",
      State: "",
      Zip: "",
      Fax: "",
      Phone: [],
      Email: []
    }
  },
  additionalContacts: [],
  serviceTypes: [],
  capabilities: [],
  alcoholPermitStates: [],
  preferredLanes: [],
  payment: {
    details: {},
  },
  taxInfo: {},
  carrierAgreeWithCATerms: false,
  insurance: {
    DocumentType: null,
    files: [],
    fileTemp: [],
  },
}

export const reducer = (state, action) => {
  switch (action.type) {
    case "setFiles":
      state.carrier.Attachments = state.carrier.Attachments ? state.carrier.Attachments : []
      state.carrier.Attachments.push(action.data);
      return {
        ...state,

      }
    case "carrierExist":
      const carrierExist = action.data
      return {
        ...state,
        carrierExist: carrierExist,
      }
    case "setCarrierSource":
      state.carrier.Source = action.data;
      console.log(state);
      return {
        ...state,

      }
    case "setCarrier":

      const carrier = action.data;
      console.log(state);
      return {
        ...state,
        carrier: carrier,
      }
    case "setCarrierType":
      const carrierType = action.data;

      return {
        ...state,
        carrierType: carrierType,
      }
    case "setTin":
      console.log(action.data);
      if (action.data === "SSN") {
        state.carrier.Ten99.SSN = true;
        state.carrier.Ten99.Ein = false;
      } else if (action.data === "EIN") {
        state.carrier.Ten99.SSN = false;
        state.carrier.Ten99.Ein = true;
      }
      // const carrierType =action.data;

      return {
        ...state

      }
    case "fileTemp":
      if (!state.fileTemp)
        state.fileTemp = [];
      state.fileTemp.push(action.data);
      return {
        ...state,
      }
    case "setDocumentType":
      return {
        ...state,
        DocumentType: action.data,

      }
    case "setAttachments":
      let docsfile = [];
      if (action.data) {
        state.Attachments = action.data;
        action.data.map(
          (file, index) => (
            file.IsDeleted === false ?
              docsfile[index] = {
                AttachmentPath: file.AttachmentPath,
                AttachmentType: file.AttachmentType,
                Date: moment(file.Date).format("MM/DD/YYYY, hh:mm:ss A")
              } : null
          ))
        state.carrier.Attachments = docsfile;
      } else { action.data.Attachments = [] }
      return {
        ...state,


      }
    case "setProgress":
      return {
        ...state,
        Progress: action.data,

      }
    case "removeFileFromList":
      let table = [];
      let documentTypes = [];
      if (action.data.type === "Authority") {
        documentTypes = ["Cargo Insurance",
          "Carrier Authority",
          "Liability Insurance",
          "Other",
        ]
      } else if (action.data.type === "W9") {
        documentTypes = ["W9"]
      }


      state.Attachments.filter((item) => {
        if (documentTypes.includes(item.AttachmentType) && item.IsDeleted === false) {
          table.push(item);
        }
      });

      var index = state.Attachments.indexOf(table[action.data.data]);

      if (index > -1) {

        state.Attachments[index].IsDeleted = true;
        if (!state.AttachmentRemoved)
          state.AttachmentRemoved = [];
        state.AttachmentRemoved.push(state.Attachments[index]);
        state.carrier.Attachments.splice(index, 1);
        state.Attachments.splice(index, 1);
      } else {
        table = [];
        var table1 = [];
        if (state.fileTemp) {
          state.Attachments.filter((item) => {
            if (documentTypes.includes(item.AttachmentType) && item.IsDeleted === false) {
              table.push(item);
            }
          });
          state.carrier.Attachments.filter((item) => {
            if (documentTypes.includes(item.AttachmentType)) {
              table1.push(item);
            }
          });
          state.fileTemp.splice(action.data.data - table.length, 1)
          state.carrier.Attachments.splice(state.carrier.Attachments.indexOf(table1[action.data.data]), 1);
        }
      }

      return {
        ...state,

      }

    case "reset":
      return initialState


    case "CarrierData":
      console.log(action.data)
      const { Name, Address, MCNum } = action.data
      const fullAddress =
        Address.Street +
        " " +
        Address.City +
        " " +
        Address.State +
        " " +
        Address.Zip
      let fullPaymentAddress = "";
      let fullBankAddress = "";
      let fullTaxAddress = "";

      if (action.data.Ten99.Address && !isEmptyObject(action.data.Ten99.Address)) {

        fullTaxAddress = state.carrier.Ten99.Address.Street + " "
          + state.carrier.Ten99.Address.City + " "
          + state.carrier.Ten99.Address.State + " "
          + state.carrier.Ten99.Address.Zip;
      }

      if (action.data.PaymentMethod === "Factoring Agent" && (action.data.FactoringCompany.PhysicalAddress && !isEmptyObject(action.data.FactoringCompany.PhysicalAddress))) {
        fullPaymentAddress = action.data.FactoringCompany.PhysicalAddress.Street + " "
          + action.data.FactoringCompany.PhysicalAddress.City + " "
          + action.data.FactoringCompany.PhysicalAddress.State + " "
          + action.data.FactoringCompany.PhysicalAddress.Zip
      } else if (state.carrier.PaymentMethod === "E-Check" && (action.data.CheckInfo.Address && !isEmptyObject(action.data.CheckInfo.Address))) {
        fullPaymentAddress = action.data.CheckInfo.Address.Street + " "
          + action.data.CheckInfo.Address.City + " "
          + action.data.CheckInfo.Address.State + " "
          + action.data.CheckInfo.Address.Zip
      } else if (state.carrier.PaymentMethod === "Direct Deposit" && (state.carrier.BankingInfo.Address && !isEmptyObject(state.carrier.BankingInfo.Address))) {
        fullBankAddress = state.carrier.BankingInfo.Address.Street + " "
          + state.carrier.BankingInfo.Address.City + " "
          + state.carrier.BankingInfo.Address.State + " "
          + state.carrier.BankingInfo.Address.Zip;
      }

      state.carrier.Name = Name;
      if (!state.carrierExist) {
        state.carrier.Address = {
          Street: Address.Street,
          City: Address.City,
          State: Address.State,
          Zip: Address.Zip,
          Fax: Address.Fax,
          Phone: [],
          Email: []
        }
      }

      let contactpersons = [];
      let carrierlanes = [];


      if (action.data.Address.Contacts) {
        action.data.Address.Contacts.map(
          (contact, index) => (
            contactpersons[index] = {
              Name: contact.Name,
              Phone: contact.Phone,
              Email: contact.Email
            }
          ))
      } else { action.data.Address.Contactss = [] }

      if (action.data.NewCarrierLanes) {
        action.data.NewCarrierLanes.map(
          (lane, index) => {
            if (lane.PointALaneAddress && lane.PointBLaneAddress) {
              carrierlanes[index] = {
                Origin: lane.PointALaneAddress.City + ", " + lane.PointALaneAddress.State,
                Destination: lane.PointBLaneAddress.City + ", " + lane.PointBLaneAddress.State
              }
            }
          })
      } else { action.data.NewCarrierLanes = [] }
      // if(state.carrier.InsuranceInformation.GLExpirationDate==="0001-01-01T00:00:00")
      // state.carrier.InsuranceInformation.GLExpirationDate=new Date();

      //   if(state.carrier.InsuranceInformation.CargoExpirationDate==="0001-01-01T00:00:00")
      // state.carrier.InsuranceInformation.CargoExpirationDate=new Date();

      // state.carrier.InsuranceInformation.GLExpirationDate = moment(action.data.InsuranceInformation.GLExpirationDate).format("YYYY-MM-DD")
      // state.carrier.InsuranceInformation.CargoExpirationDate = moment(action.data.InsuranceInformation.CargoExpirationDate).format("YYYY-MM-DD")
      state.Attachments = state.carrier.Attachments;
      state.Contacts = state.carrier.Address.Contacts;
      state.NewCarrierLanes = state.carrier.NewCarrierLanes;
      //state.carrier.Attachments = docsfile;
      state.carrier.Address.Contacts = contactpersons;
      state.preferredLanes = carrierlanes;
      state.carrier.Address.Phone[0] = Address.Phone[0];
      state.carrier.Address.Email[0] = Address.Email[0];

      //console.log( `Attachment ${state.Attachments.length}`,`Carrier : ${state.carrier.Attachments.length}`)

      return {
        ...state,
        fullAddress: fullAddress,
        fullPaymentAddress: fullPaymentAddress,
        fullBankAddress: fullBankAddress,
        fullTaxAddress: fullTaxAddress,
        MCNum: MCNum

      }
    case "verifyAddress":
      //(state);
      // state.carrier.Address=action.data;
      if (state.carrierType === "Owner Operator") {
        state.carrier.Name = action.data.name;
      }

      state.fullAddress = action.data.address;
      state.carrier.CarrierType = state.carrierType
      state.carrier.Address.Phone[0] = action.data.phone;
      state.carrier.Address.Fax = action.data.fax;
      state.carrier.Address.Email[0] = action.data.email;

      return {
        ...state,
      }
    case "setFullAddress":
      state.fullAddress = "";
      return {
        ...state,
      }
    case "setTaxInfoAddress":
      state.fullTaxAddress = action.data.full;
      state.carrier.Ten99.Address.Street = action.data.street_number + " " + action.data.route;
      state.carrier.Ten99.Address.City = action.data.locality;
      state.carrier.Ten99.Address.State = action.data.administrative_area_level_1;
      state.carrier.Ten99.Address.Zip = action.data.postal_code;
      return {
        ...state,

      }
    case "updateStreetCityStateZip":
      //console.log(action.data)

      state.carrier.Address.Street = action.data.street_number + " " + action.data.route;
      state.carrier.Address.City = action.data.locality;
      state.carrier.Address.State = action.data.administrative_area_level_1;
      state.carrier.Address.Zip = action.data.postal_code;

      return {
        ...state,

      }
    case "addAdditionalContacts":

      if (!state.carrier.Address.Contacts)
        state.carrier.Address.Contacts = [];
      state.carrier.Address.Contacts.push({ "Name": action.data.name, "Phone": action.data.phone, "Email": action.data.email });

      return {
        ...state,
      }

    case "removeContact":
      const tempcontacts = state.carrier.Address.Contacts;
      tempcontacts.splice(action.data, 1)
      const contacts = state.Contacts;
      contacts.splice(action.data, 1)


      return {
        ...state
      }
    case "setState":
      state = action.data;
      return {
        ...state
      }
    case "setServiceType":
      if (state.carrier.FleetDetails === null)
        state.carrier.FleetDetails = {}

      state.carrier.FleetDetails[action.data.name] = action.data.value;
      return {
        ...state,
      }
    // if (state.serviceTypes.indexOf(action.data) > -1) {
    //   return {
    //     ...state,
    //     serviceTypes: state.serviceTypes.filter(item => item !== action.data),
    //   }
    // } else {
    //   return {
    //     ...state,
    //     serviceTypes: [...state.serviceTypes, action.data],
    //   }
    // }
    case "setCapabilities":
      if (state.capabilities.indexOf(action.data) > -1) {
        return {
          ...state,
          capabilities: state.capabilities.filter(item => item !== action.data),
        }
      } else {
        return {
          ...state,
          capabilities: [...state.capabilities, action.data],
        }
      }

    case "setAlcoholPermitStates":
      return {
        ...state,
        alcoholPermitStates: [action.data],
      }
    case "setCarrierId":
      state.carrierid = action.data;
      return {
        ...state,
      }
    case "setPrefferedLanes":
      if (!state.carrier.NewCarrierLanes)
        state.carrier.NewCarrierLanes = []
      state.carrier.NewCarrierLanes.push({ PointALaneAddress: { "City": state.origin.originCity, "State": state.origin.originState }, PointBLaneAddress: { "City": state.destination.destinationCity, "State": state.destination.destinationState } });
      return {
        ...state,
        preferredLanes: [...state.preferredLanes, action.data],
      }
    case "setOriginLane":
      return {
        ...state,
        origin: action.data
        //preferredLanes: [...state.preferredLanes, action.data],
      }
    case "setDestinationLane":
      return {
        ...state,
        destination: action.data
        //preferredLanes: [...state.preferredLanes, action.data],
      }
    case "setUpdate":
      if (state.carrier.Attachments) {
        state.carrier.Attachments.map(
          (file, index) => (
            !state.Attachments[index] ? state.Attachments[index] = {} : null,
            state.Attachments[index].AttachmentPath = file.AttachmentPath,
            state.Attachments[index].AttachmentType = file.AttachmentType,
            state.Attachments[index].Date = file.Date,
            state.Attachments[index].ParentType = file.ParentType ? file.ParentType : "Carrier"

          ))
      } if (state.carrier.Address.Contacts) {
        state.carrier.Address.Contacts.map(
          (contact, index) => (
            !state.Contacts[index] ? state.Contacts[index] = {} : null,
            state.Contacts[index].Name = contact.Name,
            state.Contacts[index].Phone = contact.Phone,
            state.Contacts[index].Email = contact.Email
          ))
      } if (state.NewCarrierLanes) {
        state.NewCarrierLanes.map(
          (lane, index) => (
            lane.PointALaneAddress.City = state.carrier.NewCarrierLanes[index].PointALaneAddress.City,
            lane.PointALaneAddress.State = state.carrier.NewCarrierLanes[index].PointALaneAddress.State,
            lane.PointBLaneAddress.City = state.carrier.NewCarrierLanes[index].PointBLaneAddress.City,
            lane.PointBLaneAddress.State = state.carrier.NewCarrierLanes[index].PointALaneAddress.State
          ))
      }
      state.carrier.NewCarrierLanes = state.NewCarrierLanes;
      state.carrier.Address.Contacts = state.Contacts;
      state.carrier.Attachments = state.Attachments;
      console.log(state);
      return {
        ...state,

      }
    case "removePrefferedLane":
      const templanes = state.preferredLanes
      templanes.splice(action.data, 1)

      const lanes = state.NewCarrierLanes
      lanes.splice(action.data, 1)
      return {
        ...state,
        preferredLanes: templanes,
      }
    case "setPaymentMethod":
      state.carrier.PaymentMethod = action.data
      state.carrier.BankingInfo = {
        Address: {
          Street: "",
          City: "",
          State: "",
          Zip: "",
          Fax: "",
          Phone: [],
          Email: []
        }
      }
      state.carrier.CheckInfo = {

        Name: "",
        Address: {
          Street: "",
          City: "",
          State: "",
          Zip: "",
          Fax: "",
          Phone: [],
          Email: []
        },
      }
      state.carrier.FactoringCompany = {
        Name: "",
        PhysicalAddress: {
          Street: "",
          City: "",
          State: "",
          Zip: "",
          Fax: "",
          Phone: [],
          Email: []
        }
      }
      state.fullPaymentAddress = "";
      if (state.carrier.PaymentMethod === "Direct Deposit") {

        state.fullPaymentAddress = null;
      }
      return {
        ...state,
      }
    case "setQuickPay":
      console.log(action.data);
      if (action.data === "Yes")
        state.carrier.RequireQuickPay = true
      else
        state.carrier.RequireQuickPay = false
      return {
        ...state,
      }

    case "addPaymentDetails":
      //console.log()


      if (state.carrier.PaymentMethod === "E-Check") {

        state.carrier.CheckInfo.Address.Fax = action.data.fax;
        state.carrier.CheckInfo.Address.Phone[0] = action.data.phone;
        state.carrier.CheckInfo.Name = action.data.name;
        state.carrier.CheckInfo.Address.Email[0] = action.data.email;
      } else if (state.carrier.PaymentMethod === "Factoring Agent") {

        state.carrier.FactoringCompany.PhysicalAddress.Fax = action.data.fax;
        state.carrier.FactoringCompany.PhysicalAddress.Phone[0] = action.data.phone;
        state.carrier.FactoringCompany.Name = action.data.name;
        state.carrier.FactoringCompany.PhysicalAddress.Email[0] = action.data.email;
        console.log(state.carrier.FactoringCompany)
      } else {
        if (!state.carrier.BankingInfo) {
          state.carrier.BankingInfo = {}
        }
        state.carrier.BankingInfo.AccountNumber = action.data.accountNumber;
        state.carrier.BankingInfo.RoutingNumber = action.data.transitNumber;
        state.carrier.BankingInfo.BankName = action.data.bankName;
        state.carrier.BankingInfo.AccountType = action.data.accountType;
        state.carrier.RequireQuickPay = action.data.requestQP === "yes" ? true : false;
        state.carrier.BankingInfo.CashAdvance = action.data.cashAdvance;
        state.carrier.BankingInfo.Address.Email[0] = action.data.email;
      }
      return {
        ...state,

      }

    case "updateStreetCityStateZipPayment":
      //console.log(state)
      state.fullPaymentAddress = action.data.full;
      if (state.carrier.PaymentMethod === "Factoring Agent") {
        state.carrier.FactoringCompany.PhysicalAddress.Street = action.data.street_number + " " + action.data.route;
        state.carrier.FactoringCompany.PhysicalAddress.City = action.data.locality;
        state.carrier.FactoringCompany.PhysicalAddress.State = action.data.administrative_area_level_1;
        state.carrier.FactoringCompany.PhysicalAddress.Zip = action.data.postal_code;
      } else if (state.carrier.PaymentMethod === "E-Check") {
        state.carrier.CheckInfo.Address.Street = action.data.street_number + " " + action.data.route;
        state.carrier.CheckInfo.Address.City = action.data.locality;
        state.carrier.CheckInfo.Address.State = action.data.administrative_area_level_1;
        state.carrier.CheckInfo.Address.Zip = action.data.postal_code;
      }

      return {
        ...state

      }
    case "updateBankAddress":
      //console.log(action.data)
      state.fullBankAddress = action.data.full;
      state.carrier.BankingInfo.Address.Street = action.data.street_number + " " + action.data.route;
      state.carrier.BankingInfo.Address.City = action.data.locality;
      state.carrier.BankingInfo.Address.State = action.data.administrative_area_level_1;
      state.carrier.BankingInfo.Address.Zip = action.data.postal_code;

      return {
        ...state

      }
    // case "addInsuranceInfo":
    //   state.carrier.InsuranceInformation = {
    //     GLPolicyNum: action.data.galpolicynum,
    //     GLExpirationDate: action.data.galexpirationdate,
    //     CargoPolicyNum: action.data.cargopolicynum,
    //     CargoExpirationDate: action.data.cargoexpirationdate,
    //   }

    //   return {
    //     ...state,
    //   }
    case "setDispatcher":
      state.dispatcher = action.data;
      return {
        ...state,
      }
    case "verifyPhone":
      if (action.data.type === "Phone")
        state.carrier.Address.Phone[0] = action.data.value
      else if (action.data.type === "Fax")
        state.carrier.Address.Fax = action.data.value

      return {
        ...state,
      }
    case "verifyTaxPhone":
      if (action.data.type === "Phone") {
        if (state.carrier.PaymentMethod === "Factoring Agent")
          state.carrier.FactoringCompany.PhysicalAddress.Phone[0] = action.data.value
        else
          state.carrier.CheckInfo.Address.Phone[0] = action.data.value
      }
      else if (action.data.type === "Fax") {
        if (state.carrier.PaymentMethod === "Factoring Agent")
          state.carrier.FactoringCompany.PhysicalAddress.Fax = action.data.value
        else
          state.carrier.CheckInfo.Address.Fax = action.data.value
      }
      return {
        ...state,
      }
    case "addTaxInfo":
      const ssn = state.carrier.Ten99.SSN;
      const ein = state.carrier.Ten99.Ein;
      state.carrier.FedTaxId = action.data.tin
      state.carrier.Ten99 = {
        CompanyType: action.data.companyType,
        Ein: ein,
        SSN: ssn,
        Address: state.carrier.Ten99.Address,
        DateConfirmed: new Date(),
        AcceptTaxInfoTerms: action.data.acceptTaxInfoTerms === "yes" ? true : false,
        ConfirmationCheck: action.data.acceptTaxInfoTerms === "yes" ? true : false,
      }
      console.log(state.carrier.Ten99)
      return {
        ...state,
      }
    case "setTaxInfoAddress":
      state.fullTaxAddress = action.data.full;
      state.carrier.Ten99.Address.City = action.data.locality;
      state.carrier.Ten99.Address.State = action.data.administrative_area_level_1;
      state.carrier.Ten99.Address.Zip = action.data.postal_code;
      return {
        ...state,

      }

    case "setCarrierAgreeWithTerms":
      state.carrier.AgreedWithCATerms = action.data
      return {
        ...state,
      }
    case "setCarrierAgreementInfo":
      state.carrier.AcceptedBy = action.data.name;
      state.carrier.AcceptedByTitle = action.data.title;
      return {
        ...state,
      }
    default:
      return state
  }
}

export const Context = React.createContext()
