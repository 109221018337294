import React from "react"
export default function Checkbox({
  label,
  name,
  isSelected,
  value,
  onCheckboxChange,
  registerFn
}) {  
  return (
    <label className="block text-gray-900">
      <input
        className="mr-2 leading-tight"
        type="checkbox"
        name={name}
        defaultChecked={isSelected}
        onChange={onCheckboxChange}
        value={value||label}
        ref={registerFn}
      />
      <span className={label==="Dry Van"||label==="Flatbed"||label==="Refrigerated"?"font-sans font-bold break-normal text-gray-700 text-xl mb-12 mb-2 lg:mt-0":"text-sm"}>{label}</span>
    </label>
  )
}
