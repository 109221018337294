import React from "react";

export default function Submit({ title, type, isEnabled}) {

  const disabled = "bg-gray-600 text-white font-bold py-2 px-4 rounded inline-flex items-center cursor-not-allowed"
  const enabled = "bg-black hover:bg-blue-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline inline-flex items-center"


  return (
    <>
      <button
        className={(isEnabled ? disabled: enabled)}        
        type={type}
        disabled={isEnabled}
      >
        {title}
      </button>
    </>
  );
}
